import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import React from 'react';

export type ErrorFallbackProps = {
  error?: Error | undefined;
  maxHeight?: number;
  children: React.ReactNode;
} & UIComponentProps;

export const ErrorBoundaryFallback = ({ error }: ErrorFallbackProps) => {
  return (
    <div className={'m-4'}>
      <div className={'bg-error-800 rounded-lg p-4 text-white'}>
        <span className={'text-lg font-bold'}>Error: </span>
        {error?.message ? <span>{error.message}</span> : null}
      </div>
    </div>
  );
};
