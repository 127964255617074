'use client';

import React from 'react';
import type { UIThemableComponent } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import { UISizes } from '../types/UI';

export type TextProps = UIThemableComponent & {
  as?: 'p' | 'span' | 'div' | undefined;
};

export const Text = ({ as, size = UISizes.md, children, className, ...otherProps }: TextProps) => {
  const { theme } = useTheme();
  const themableProps = { size, theme };

  if (!as) return children;

  return React.createElement(
    as,
    {
      ...otherProps,

      className: cn([UIStyling.textSizes(themableProps), className]),
    },
    children
  );
};
