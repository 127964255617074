'use client';

import React from 'react';
import type { RecursiveKeyValuePair, UIComponentProps } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';
import type { ColorPaletteCompactProps } from '.';
import { ColorPaletteCompact } from '.';
import { InputColor } from '../InputColor';
import type { ColorPaletteConfig } from '../types/theme';
import generateMaterialPalette from '../utils/material-palette';

export type ColorPaletteEditableProps = UIComponentProps &
  Omit<ColorPaletteCompactProps, 'palette'> & {
    paletteName: string;
    value: string;
    showPickerLabel?: boolean;
    hidePalette?: boolean;
    palette?: RecursiveKeyValuePair<string, string>;
    onChange?: (paletteConfig: ColorPaletteConfig) => void;
  };

export const ColorPaletteEditable = ({
  className,
  hidePalette,
  paletteName,
  showPickerLabel,
  showLabels,
  palette,

  onChange,
  value,
  ...otherProps
}: ColorPaletteEditableProps) => {
  const handleValueChange = React.useCallback(
    (value?: string) => {
      const newColor = value || '#000';
      const newPalette = generateMaterialPalette(newColor);
      onChange?.({ id: paletteName, color: newColor, palette: newPalette });
    },
    [onChange, paletteName]
  );

  return (
    <div {...otherProps} className={cn('flex flex-col gap-2', className)}>
      {!hidePalette && palette && <ColorPaletteCompact label={paletteName} showLabels={showLabels} palette={palette} />}
      <div className="flex flex-1 items-center gap-2">
        {showPickerLabel && <div className="w-24 text-right text-sm">{paletteName}</div>}
        {value && <InputColor className="flex-1" size="sm" value={value} onValueChange={handleValueChange} />}
      </div>
    </div>
  );
};
