import type { MsApiErrorResponse } from '@wolfejs/ms-api-js/src/types/api';
import { AxiosError } from 'axios';

export const handleApiSSRError = (error?: MsApiErrorResponse | undefined) => {
  if (!error) {
    console.error('Unknown error in SSR component.');
    throw new Error('Oops! Something went wrong.');
  }

  const message = Array.isArray(error?.message) ? error.message.join(';') : error.message;
  console.error(error, 'SSR Error: ', message || `${error.statusCode} - ${error?.error}`);
  throw new Error(message || `${error.statusCode} - ${error?.error}`);
};

export const handleApiClientError = (error: unknown): string => {
  if (error instanceof AxiosError) {
    //@ts-ignore
    const message = error.response?.data.message;
    console.error(error, `Client Error: ${message}`);
    return message;
  } else {
    console.error(error, `Client Error`);
    return `Client Error: ${error instanceof Error ? error?.message : error}`;
  }
};
