'use client';

import type { PortalThemeSettings } from '@wolfejs/ms-ui/types/PortalThemeSettings';
import React from 'react';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { useTheme } from '../hooks/useTheme';
import { useThemeStore } from '../stores/ThemeStore';
import { useUIStore } from '../stores/UIStore';
import { updateAllThemes, updatePortalVariables } from '../styles/themeGenerator';
import { ThemeModes, type ThemeConfig } from '../types/theme';

export type ThemeMappingProviderProps = {
  themeConfigs: ThemeConfig[];
  portalThemeSettings?: PortalThemeSettings;
};

/**
 * The job of this provider is to store the theme configurations into the ThemeStore and
 * render the css variables mappings to the html.
 */
export const ThemeMappingProvider = ({ themeConfigs, portalThemeSettings }: ThemeMappingProviderProps) => {
  const [mounted, setMounted] = React.useState<boolean>(false);
  const [initialized, setInitialized] = React.useState<boolean>(false);

  // const theme = useUIStore(state => state.theme);
  const setStoreTheme = useUIStore(state => state.setTheme);
  const setThemeMode = useUIStore(state => state.setThemeMode);

  const { theme } = useTheme();

  const storeTheme = useUIStore(state => state.theme);
  const themeMode = useUIStore(state => state.themeMode);

  const storeThemeConfigs = useThemeStore(state => state.themeConfigs);
  const setThemeConfigs = useThemeStore(state => state.setThemeConfigs);

  React.useEffect(() => {
    if (!mounted) return;
    if (!storeTheme) setStoreTheme('flowbite');
  }, [mounted, setStoreTheme, storeTheme, theme]);

  React.useEffect(() => {
    if (storeTheme) {
      document.body.classList.add(themeMode);
    }
  }, [storeTheme, themeMode]);

  React.useEffect(() => {
    document.body.style.visibility = 'visible';

    document.body.classList.add(themeMode);
  }, [themeMode]);

  React.useEffect(() => {
    if (!mounted || initialized) return;

    // store all themes in the store
    if (!storeThemeConfigs) setThemeConfigs(themeConfigs);

    if (storeTheme) document.body.classList.add(themeMode);

    if (storeThemeConfigs) {
      // apply css variables for all themes
      updateAllThemes(themeConfigs, { themeMode });

      const currentTheme = themeConfigs.find(cfg => cfg.id === theme);

      if (currentTheme && portalThemeSettings) {
        updatePortalVariables(currentTheme, portalThemeSettings, { theme, themeMode });
      }

      setInitialized(true);
    }
  }, [
    initialized,
    mounted,
    setThemeConfigs,
    storeThemeConfigs,
    storeTheme,
    theme,
    themeConfigs,
    themeMode,
    portalThemeSettings,
  ]);

  useEffectOnce(() => {
    if (themeMode === ThemeModes.System) {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode(ThemeModes.Dark);
      } else {
        setThemeMode(ThemeModes.Light);
      }
    }

    setMounted(true);
  });

  return null;
};
