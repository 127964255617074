'use client';

import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';
import React from 'react';
import type { DialogRenderProps } from './Dialog';

export type DialogTitleProps = UIComponentProps & DialogRenderProps;

const DialogTitleComp = (
  { className, children, close: _close, ...otherProps }: DialogTitleProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <div className={cn('block text-2xl font-bold', className)} ref={ref} {...otherProps}>
      {children}
    </div>
  );
};

export const DialogTitle = React.forwardRef(
  DialogTitleComp
) as React.ForwardRefExoticComponentExtended<DialogTitleProps>;
