'use client';

import React from 'react';
import type { UIThemableComponent } from '@wolfejs/ui/types/UI';
import { UIColors, UIShapes, UISizes, UIVariants } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';
import { UIStyling } from '../styles/UIStyling';

export type ColorSwatchProps = UIThemableComponent & {
  color?: string;
  variable?: string;
};

export const ColorSwatch = ({
  className,
  color = UIColors.default,
  variable,
  size = UISizes.md,
  variant = UIVariants.solid,
  shape = UIShapes.rounded,
  ...otherProps
}: ColorSwatchProps) => {
  const themableProps = { variant, color, shape, size };

  return (
    <div
      style={{ background: variable ? `var(${variable})` : color }}
      {...otherProps}
      className={cn(
        'rounded border-2 border-gray-400 ',
        UIStyling.squareSizesForm(themableProps),
        UIStyling.shapes(themableProps),
        className
      )}
    />
  );
};
