import translation from 'zod-i18n-map/locales/fr/zod.json';

export default {
  business: 'Business fr',
  funds: {
    availableBalance: 'addasd',
  },
};

export const zodValidations = {
  ...translation, // apply the default french zod messages
  // override zod messages here if needed
};
