import { isBrowser } from '@wolfejs/core/utils/environment';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ThemeModes } from '../types/theme';

const defaultThemeMode = ThemeModes.Light;

export type UIStoreProps = {
  language: string;
  setLanguage: (language?: string) => void;
  theme: string;
  setTheme: (theme?: string) => void;
  themeMode: string;
  setThemeMode: (themeMode: string) => void;
  isNavigating: boolean;
  setIsNavigating: (isNavigating: boolean) => void;
};

export const UIStore: StateCreator<UIStoreProps, [], [], UIStoreProps> = set => ({
  theme: '',
  setTheme: (theme?: string) => set(() => ({ theme })),
  themeMode: defaultThemeMode,
  setThemeMode: (themeMode: string) => set(() => ({ themeMode })),
  isNavigating: false,
  setIsNavigating: (isNavigating: boolean) => set(() => ({ isNavigating })),
  language: isBrowser ? navigator?.language ?? 'en' : 'en',
  setLanguage: (language?: string) => set(() => ({ language })),
});

export const useUIStore = create<UIStoreProps>()(
  persist(UIStore, {
    name: 'UI',
  })
);
