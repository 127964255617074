import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '@wolfejs/icons/IconMap';
import React from 'react';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import type { UIThemableComponent } from '../types/UI';
import { UIColorContext, UIColorStates, UIShapes, UISizes, UIVariants } from '../types/UI';
import { cn } from '../utils/classnames';

export type NotificationBaseProps = UIThemableComponent & {
  // ref?: React.ForwardedRef<HTMLDivElement>;

  // ----- options
  fullWidth?: boolean;
  title?: React.ReactNode;
  Icon?: React.ComponentType;
  append?: React.ReactNode;
};

export const NotificationComp = (
  {
    children,
    className,

    // ----- Themable props defaults
    color = UIColorContext.default,
    variant = UIVariants.solid,
    shape = UIShapes.rounded,
    size = UISizes.md,
    gradient,
    elevation,

    // ----- options
    append,
    fullWidth,
    title,
    Icon,

    // ----- slots
    classNames,

    ...otherProps
  }: NotificationBaseProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) => {
  const cname = 'ui-notification';
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation };

  const commonProps = {
    ref: forwardedRef,
    className:
      variant === UIVariants.unstyled
        ? cname
        : cn(
            cname,
            'inline-flex',
            // ----- Add standard set of styling classes
            UIStyling.variants(themableProps),
            UIStyling.shapes(themableProps),
            UIStyling.textSizes(themableProps),
            UIStyling.elevations(themableProps),
            {
              // ----- Paddings for various sizes
              [`size-xs px-1 py-[2px]`]: size === UISizes.xs,
              [`size-sm px-2 py-1`]: size === UISizes.sm,
              [`size-md px-2 py-2`]: size === UISizes.md,
              [`size-lg px-2 py-2`]: size === UISizes.lg,
              [`size-xl px-2 py-2`]: size === UISizes.xl,

              // ----- Others
              [`flex`]: fullWidth,
            },
            className
          ),

    ...otherProps,
  };

  if (!Icon) {
    if (color === 'info') Icon = InfoIcon;
    if (color === 'error') Icon = ErrorIcon;
    if (color === 'warning') Icon = WarningIcon;
    if (color === 'success') Icon = SuccessIcon;
  }

  return (
    <div {...commonProps}>
      <div className={cn('flex flex-1 items-center justify-between', classNames?.layout)}>
        <div className={cn('flex flex-1 items-center gap-2', classNames?.contentWrapper)}>
          {Icon && (
            <div className={cn(classNames?.icon)}>
              <Icon />
            </div>
          )}
          <div className={cn('block', classNames?.content)}>
            {title && <div className={cn('text-lg font-bold', classNames?.title)}>{title}</div>}
            {children && <div className={cn(classNames?.label)}>{children}</div>}
          </div>
        </div>
      </div>
      {append && <div className={cn('ml-4 flex gap-2', classNames?.append)}>{append}</div>}
    </div>
  );
};

export const NotificationBase = React.forwardRef<HTMLDivElement, NotificationBaseProps>(
  NotificationComp
) as React.ForwardRefExoticComponentExtended<NotificationBaseProps>;
// NotificationBase.displayName = 'Notification';

NotificationBase.colors = [UIColorContext.default, ...Object.values(UIColorStates)];
