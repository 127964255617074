import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import useLatest from 'react-use/lib/useLatest';
import type { DialogProps } from '../Dialog/Dialog';
import { Dialog } from '../Dialog/Dialog';
import { UISizes } from '../types/UI';
import { cn } from '../utils/classnames';

export enum ModalPositions {
  Fullscreen = 'fullscreen',
  Center = 'center',
  TopCenter = 'top-center',
}

export type ModalProps = DialogProps & {
  open?: boolean;
  maxWidth?: UISizes;
  position?: ModalPositions;
  backdrop?: boolean;
  closeOnEscape?: boolean;
  closeOnBackdropClick?: boolean;
  onClosed?: () => void;
  onOpened?: () => void;
  onBackdropClick?: () => void;
};

export function Modal({
  className,
  open,
  children,
  maxWidth = UISizes['2xl'],
  position = ModalPositions.Center,
  backdrop = true,
  closeOnEscape = true,
  closeOnBackdropClick = true,
  classNames,
  onClose,
  onOpened,
  onClosed,
  onBackdropClick,
  ...otherProps
}: ModalProps) {
  const [closing, setClosing] = React.useState<boolean>(false);
  const closingLatest = useLatest(closing);

  const handleClosed = React.useCallback(() => {
    onClosed?.();
    setClosing(false);
    document.body.classList.remove('has-overlay');
  }, [onClosed]);

  const _close = React.useCallback(
    (reason?: unknown) => {
      if (!open) return;
      setClosing(true);
      onClose?.(reason);
    },
    [onClose, open]
  );

  const handleKeyDown = React.useCallback(
    (ev: KeyboardEvent) => {
      if (ev.code === 'Escape') {
        if (closeOnEscape) _close('escape');
      }
    },
    [_close, closeOnEscape]
  );

  const handleOpened = React.useCallback(() => {
    if (open && !closing) {
      onOpened?.();
      document.body.classList.add('has-overlay');
    }
  }, [closing, onOpened, open]);

  const handleBackdropClick = React.useCallback(() => {
    onBackdropClick?.();
    if (closeOnBackdropClick) _close('backdrop-click');
  }, [_close, closeOnBackdropClick, onBackdropClick]);

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <AnimatePresence initial={false}>
      {open && (
        <div
          className={cn(
            [{ open }],
            'z-modal fixed inset-0 flex items-center justify-center overflow-auto',
            {
              'items-start': position === ModalPositions.TopCenter,
            },
            classNames?.modal
          )}
        >
          {backdrop && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.8 }}
              exit={{ opacity: 0, transition: { duration: 0.2 } }}
              className={cn('backdrop', undefined, 'bg-default-900 fixed inset-0 opacity-80')}
              onClick={handleBackdropClick}
            />
          )}

          <motion.div
            initial={{ opacity: 0, marginTop: '20px' }}
            animate={{ opacity: 1, marginTop: '0px' }}
            exit={{ opacity: 0, marginTop: '20px', transition: { duration: 0.2 } }}
            className={cn(
              `bg-default text-default-foreground fixed flex h-auto w-full flex-col rounded-lg shadow-xl`,
              { [`max-w-${maxWidth}`]: position !== ModalPositions.Fullscreen },
              { 'h-full': position === ModalPositions.Fullscreen },
              className
            )}
            onAnimationComplete={() => {
              closingLatest.current ? handleClosed() : handleOpened();
            }}
          >
            <Dialog {...otherProps} onClose={_close} className={cn('h-full', className)}>
              {children}
            </Dialog>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}
