'use client';

import { Title } from '@wolfejs/ui/Title';
import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';

export type DevToolBoxSectionProps = UIComponentProps & {
  title: React.ReactNode;
};

export const DevToolBoxSection = ({ className, children, title, ...otherProps }: DevToolBoxSectionProps) => {
  return (
    <div {...otherProps} className={cn('border-default-500 relative mt-2 border p-2 pt-6', className)}>
      <Title as="h4" className={cn('bg-default-200 dark:bg-default-700 absolute -top-4 inline-block px-2')}>
        {title}
      </Title>
      {children}
    </div>
  );
};
