import translation from 'zod-i18n-map/locales/en/zod.json';

export default {
  business: 'Business',
  funds: {
    availableBalance: 'Available Balance',
  },
  errors: {
    generalError: 'An error occurred, please try again.',
  },
};

export const zodValidations = {
  ...translation, // apply the default english zod messages
  // override zod messages here if needed
};
