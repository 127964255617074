import type React from 'react';
import ReactDOM from 'react-dom';
import { isBrowser } from '@wolfejs/core/utils/environment';

export function renderPortalWithRef(node: React.ReactNode, ref: React.MutableRefObject<HTMLElement>) {
  if (isBrowser && ref && ref.current) return ReactDOM.createPortal(node, ref.current);
  return node;
}

export function renderPortal(node: React.ReactNode, portal: () => Element | undefined) {
  if (isBrowser && portal) {
    const el = portal();
    return el ? ReactDOM.createPortal(node, el) : node;
  }
  return node;
}
