import type { ColorPaletteConfig, ThemeConfig } from '../../types/theme';
import generateMaterialPalette from '../../utils/material-palette';
import { colorShades } from '../UIStyling';

const palettes: ColorPaletteConfig[] = [
  {
    id: 'zinc',
    color: '#71717a',
    palette: generateMaterialPalette('#71717a'),
  },
  {
    id: 'emerald',
    color: '#10b981',
    palette: generateMaterialPalette('#10b981'),
  },
  {
    id: 'purple',
    color: '#6b21a8',
    palette: generateMaterialPalette('#6b21a8'),
  },
  {
    id: 'amber',
    color: '#b45309',
    palette: generateMaterialPalette('#b45309'),
  },
  {
    id: 'blue',
    color: '#1e40af  ',
    palette: generateMaterialPalette('#1e40af  '),
  },
  {
    id: 'red',
    color: '#b91c1c',
    palette: generateMaterialPalette('#b91c1c'),
  },
  {
    id: 'orange',
    color: '#c2410c',
    palette: generateMaterialPalette('#c2410c'),
  },
  {
    id: 'green',
    color: '#16a34a',
    palette: generateMaterialPalette('#16a34a'),
  },
];

const theme: ThemeConfig = {
  id: 'tailwind',
  name: 'Tailwind theme',
  light: {
    paletteConfigs: palettes.concat([]),
    contextualMappings: [
      {
        id: 'default',
        paletteName: 'zinc',
      },
      {
        id: 'primary',
        paletteName: 'emerald',
      },
      {
        id: 'secondary',
        paletteName: 'purple',
      },
      {
        id: 'accent',
        paletteName: 'amber',
      },
      {
        id: 'info',
        paletteName: 'blue',
      },
      {
        id: 'error',
        paletteName: 'red',
      },
      {
        id: 'warning',
        paletteName: 'orange',
      },
      {
        id: 'success',
        paletteName: 'green',
      },
    ],
    contextualStates: (color: string) => {
      const shadeMappings: Record<string, string> = {};
      colorShades.forEach(shade => {
        shadeMappings[shade] = `var(--${color}-${shade})`;
      });
      return {
        __default__: {
          DEFAULT: `var(--${color}-300)`,
          hover: `var(--${color}-400)`,
          active: `var(--${color}-500)`,
          foreground: `var(--foreground)`,
          ...shadeMappings,
        },
      };
    },
    contextualGradients: {
      __default__: {
        DEFAULT: 'from-${color}-500 via-${color}-600 to-${color}-700',
        hover: 'var(--${color}-400)',
        active: 'var(--${color}-500)',
        foreground: `var(--foreground)`,
      },
    },
    colors: {
      border: 'var(--zinc-500)',

      // backgrounds
      background: 'var(--zinc-100)',
      section: 'var(--zinc-200)',
      card: 'var(--zinc-200)',
      'table-header': 'var(--zinc-400)',

      // foregrounds
      foreground: 'var(--zinc-900)',
      muted: 'var(--zinc-600)',
      code: '#5720b6',
      link: '#3b82f6',

      // scrollbar
      ['scrollbar-width']: '12px',
      ['scrollbar-bg']: 'var(--zinc-200)',
      ['scrollbar-thumb']: 'var(--zinc-300)',
      ['scrollbar-padding']: '2px',
      ['scrollbar-border-radius']: '30px',

      // Prose article
      '.prose': {
        [`tw-prose-body`]: 'var(--zinc-900)',
        [`tw-prose-quotes`]: 'var(--zinc-900)',
        [`tw-prose-headings`]: 'var(--zinc-900)',
        [`tw-prose-links`]: '#3b82f6',
        [`tw-prose-bold`]: 'var(--zinc-900)',
      },
    },
  },
  dark: {
    paletteConfigs: palettes.concat([]),
    contextualMappings: [
      {
        id: 'default',
        paletteName: 'zinc',
      },
      {
        id: 'primary',
        paletteName: 'emerald',
      },
      {
        id: 'secondary',
        paletteName: 'purple',
      },
      {
        id: 'accent',
        paletteName: 'amber',
      },
      {
        id: 'info',
        paletteName: 'blue',
      },
      {
        id: 'error',
        paletteName: 'red',
      },
      {
        id: 'warning',
        paletteName: 'orange',
      },
      {
        id: 'success',
        paletteName: 'green',
      },
    ],
    contextualStates: (color: string) => {
      const shadeMappings: Record<string, string> = {};
      colorShades.forEach(shade => {
        shadeMappings[shade] = `var(--${color}-${shade})`;
      });
      return {
        __default__: {
          DEFAULT: `var(--${color}-600)`,
          hover: `var(--${color}-500)`,
          active: `var(--${color}-400)`,
          foreground: `var(--foreground)`,
          ...shadeMappings,
        },
      };
    },
    contextualGradients: {
      __default__: {
        DEFAULT: 'from-${color}-500 via-${color}-600 to-${color}-700',
        hover: 'var(--${color}-400)',
        active: 'var(--${color}-500)',
        foreground: `var(--foreground)`,
      },
    },
    colors: {
      border: 'var(--zinc-500)',

      // backgrounds
      background: '#222',
      section: 'var(--zinc-600)',
      card: 'var(--zinc-700)',
      'table-header': 'var(--zinc-700)',

      // foregrounds
      foreground: 'var(--zinc-100)',
      muted: 'var(--zinc-400)',
      code: '#7E3AF2',
      link: '#3b82f6',

      // scrollbar
      ['scrollbar-width']: '12px',
      ['scrollbar-bg']: 'var(--zinc-200)',
      ['scrollbar-thumb']: 'var(--zinc-300)',
      ['scrollbar-padding']: '2px',
      ['scrollbar-border-radius']: '30px',

      // Prose article
      '.prose': {
        [`tw-prose-body`]: 'var(--zinc-200)',
        [`tw-prose-quotes`]: 'var(--zinc-200)',
        [`tw-prose-headings`]: 'var(--zinc-200)',
        [`tw-prose-links`]: '#3b82f6',
        [`tw-prose-bold`]: 'var(--zinc-200)',
      },
    },
  },
};

export default theme;
