'use client';

import { produce } from 'immer';
import React from 'react';
import { InputColorVar } from '@wolfejs/ui/InputColor';
import { useThemeStore } from '@wolfejs/ui/stores/ThemeStore';
import { useUIStore } from '@wolfejs/ui/stores/UIStore';
import { updateTheme } from '@wolfejs/ui/styles/themeGenerator';
import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';

export type ColorPickersProps = UIComponentProps & {
  varNames: string[];
};

export const ColorPickers = ({ className, varNames, ...otherProps }: ColorPickersProps) => {
  const theme = useUIStore(state => state.theme);
  const themeMode = useUIStore(state => state.themeMode);

  const _themeConfigs = useThemeStore(state => state.themeConfigs);
  const setThemeConfigs = useThemeStore(state => state.setThemeConfigs);

  const config = _themeConfigs?.find(conf => conf.id === theme);

  const handleColorChange = React.useCallback(
    (color: string, name: string) => {
      if (!config) return;

      const newConfig = produce(config, draft => {
        if (!draft) return;
        const conf = themeMode === 'light' ? draft.light : draft.dark;
        conf.colors[name] = color;
      });

      if (!newConfig) return;

      const newConfigs = produce(_themeConfigs, draft => {
        if (!draft) return;
        const index = draft.findIndex(item => item.id === config.id);
        if (index > -1) draft[index] = newConfig;
      });

      if (newConfigs) setThemeConfigs(newConfigs);

      const conf = themeMode === 'light' ? newConfig.light : newConfig.dark;
      updateTheme(newConfig, { paletteConfigs: conf.paletteConfigs, theme, themeMode });
    },
    [_themeConfigs, config, setThemeConfigs, theme, themeMode]
  );

  return (
    <div {...otherProps} className={cn('flex flex-col gap-1', className)}>
      {varNames.map(name => {
        return (
          <div key={name} className="flex items-center gap-2">
            <span className="w-24 text-right text-sm">{name}</span>
            <InputColorVar
              size="sm"
              selector={`:root .${themeMode}`}
              variable={`--${name}`}
              onValueChange={color => handleColorChange(color, name)}
            />
          </div>
        );
      })}
    </div>
  );
};
