import type { RecursiveKeyValuePair } from './UI';

export type ColorPaletteObject = RecursiveKeyValuePair<string, string>;

export type ColorPaletteConfig = {
  id: string;
  color: string;
  palette: ColorPaletteObject;
};

export type ColorPaletteMapping = {
  id: string;
  paletteName: string;
};

export type ThemeContextualState = Record<string, string>;
export type ThemeContextualStates = Record<string, ThemeContextualState>;
export type ThemeColorsObject = Record<string, string>;
export type ThemeColors = Record<string, string | ThemeColorsObject>;

export type ThemeConfig = {
  id: string;
  name: string;
  light: ThemeModeConfig;
  dark: ThemeModeConfig;
};

export type ThemeModeConfig = {
  paletteConfigs: ColorPaletteConfig[];
  contextualMappings: ColorPaletteMapping[];
  contextualStates: (color: string) => ThemeContextualStates;
  contextualGradients?: ThemeContextualStates;
  colors: ThemeColors;
};

export enum ThemeModes {
  System = 'system',
  Light = 'light',
  Dark = 'dark',
}
