'use client';

import React from 'react';
import type { ChromePickerProps, ColorResult } from 'react-color';
import { ChromePicker } from 'react-color';
import { ColorSwatch } from '../ColorSwatch/ColorSwatch';
import type { InputProps } from '../Input/Input';
import { Input } from '../Input/Input';
import { Popover } from '../Popover';
import { UISizes } from '../types/UI';
import { cn } from '../utils/classnames';

export type InputColorProps = InputProps & {
  value?: string;
  pickerOptions?: ChromePickerProps;
};

export const InputColor = ({
  value,
  initialValue,
  pickerOptions,
  classNames,
  className,
  size = UISizes.md,
  onValueChange,
  onChange,
  ...otherProps
}: InputColorProps) => {
  const [_value, setValue] = React.useState<string | undefined>(initialValue);

  const isControlled = !!value;
  const val = isControlled ? value : _value;

  const handleColorChange = React.useCallback(
    (color: ColorResult) => {
      onValueChange?.(color.hex);

      if (!isControlled) setValue(color.hex);
    },
    [onValueChange, isControlled]
  );

  const handleChange = React.useCallback(
    (ev?: React.ChangeEvent<HTMLInputElement>) => {
      const color = ev?.target.value;
      if (ev) onChange?.(ev);
      onValueChange?.(color || '#000');

      if (!isControlled) setValue(color);
    },
    [onChange, onValueChange, isControlled]
  );

  return (
    <div className={cn('flex items-center gap-2', className)}>
      <Popover
        useClick
        trigger={<ColorSwatch size={size} className={cn('cursor-pointer', classNames?.swatch)} color={val} />}
      >
        <ChromePicker {...pickerOptions} color={val} onChange={handleColorChange} />
      </Popover>
      <Input className="flex-1" size={size} value={val} onChange={handleChange} {...otherProps} />
    </div>
  );
};
