// @ts-ignore
import hexToHsl from 'hex-to-hsl';
// @ts-ignore
import hslToHex from 'hsl-to-hex';
// @ts-ignore
import materialpalette from 'material-palette';

export default function generateColorPalette(baseColor: string) {
  const hsl = hexToHsl(baseColor);
  const color = materialpalette({ h: hsl[0], s: hsl[1], l: hsl[2] });

  const paletteHex: Record<string, string> = {};
  Object.keys(color).map(key => {
    const hsl = color[key];
    const hex = hslToHex(hsl.h, hsl.s, hsl.l);
    paletteHex[key] = hex;
  });

  return paletteHex;
}
