'use client';

import type { MainMenuItemRecursive } from '@wolfejs/ui/Menu';
import cn from 'classnames';
import Link from 'next/link';

type FooterLinksComponentProps = {
  items: MainMenuItemRecursive[];
};

export const FooterLinks = ({ items }: FooterLinksComponentProps) => {
  return (
    <div
      id="footer-links-container"
      className={cn(
        'ml-0 flex flex-col flex-wrap justify-center gap-4 text-center @xl:flex-row @xl:gap-6 @xl:text-left xl:ml-[-5rem]',
        '@xl:flex-1'
      )}
    >
      {items?.map(item => (
        <Link
          key={item.text}
          className={cn('text-center text-base font-medium text-default-900 underline hover:text-primary')}
          href={item.url || '/'}
        >
          {item.text}
        </Link>
      ))}
    </div>
  );
};
