'use client';

import { produce } from 'immer';
import React from 'react';
import { Button } from '@wolfejs/ui/Button/Button';
import { ColorPaletteEditable } from '@wolfejs/ui/ColorPalette/ColorPaletteEditable';
import { useThemeStore } from '@wolfejs/ui/stores/ThemeStore';
import { useUIStore } from '@wolfejs/ui/stores/UIStore';
import { updateTheme } from '@wolfejs/ui/styles/themeGenerator';
import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import type { ColorPaletteConfig, ThemeConfig } from '@wolfejs/ui/types/theme';
import { cn } from '@wolfejs/ui/utils/classnames';

export type ColorPaletteEditorProps = UIComponentProps & {
  resetButton?: boolean;
  hidePalettes?: boolean;
  showPickerLabels?: boolean;
  themeConfigs: ThemeConfig[];
};

export const ColorPaletteEditor = ({
  themeConfigs,
  className,
  hidePalettes,
  showPickerLabels,
  resetButton,
  ...otherProps
}: ColorPaletteEditorProps) => {
  const theme = useUIStore(state => state.theme);
  const themeMode = useUIStore(state => state.themeMode);

  const _themeConfigs = useThemeStore(state => state.themeConfigs);
  const setThemeConfigs = useThemeStore(state => state.setThemeConfigs);

  const config = _themeConfigs?.find(conf => conf.id === theme);

  const handleResetAll = React.useCallback(() => {
    if (!config) return;

    // reset to default color palettes
    setThemeConfigs(themeConfigs);
    updateTheme(config, { theme, themeMode });
  }, [config, setThemeConfigs, theme, themeConfigs, themeMode]);

  const handleChange = React.useCallback(
    (paletteConfig: ColorPaletteConfig) => {
      if (!config) return;

      const newConfig = produce(config, draft => {
        if (!draft) return;
        const conf = themeMode === 'light' ? draft.light : draft.dark;
        const index = conf.paletteConfigs.findIndex(item => item.id === paletteConfig.id);

        conf.paletteConfigs[index] = paletteConfig;
      });

      const newConfigs = produce(_themeConfigs, draft => {
        if (!draft) return;
        const index = draft.findIndex(item => item.id === config.id);
        if (index > -1) draft[index] = newConfig;
      });

      if (newConfigs) setThemeConfigs(newConfigs);

      const conf = themeMode === 'light' ? newConfig.light : newConfig.dark;
      updateTheme(config, { paletteConfigs: conf.paletteConfigs, theme, themeMode });
    },
    [_themeConfigs, config, setThemeConfigs, theme, themeMode]
  );

  if (!config) return <div> no palettes found </div>;

  const themeModeConfig = themeMode === 'light' ? config.light : config.dark;

  return (
    <>
      <div {...otherProps} className={cn('color-palette-editor mb-4 flex flex-wrap gap-4', className)}>
        {themeModeConfig.paletteConfigs.map(paletteConfig => {
          if (!paletteConfig) return null;
          return (
            <ColorPaletteEditable
              hidePalette={hidePalettes}
              showPickerLabel={showPickerLabels}
              key={paletteConfig.id}
              value={paletteConfig.color}
              paletteName={paletteConfig.id}
              showLabels
              onChange={handleChange}
              palette={paletteConfig.palette}
            />
          );
        })}
      </div>
      {resetButton && <Button onClick={handleResetAll}>Reset all palettes</Button>}
    </>
  );
};
