'use client';

import React from 'react';
import { CloseIcon } from '@wolfejs/icons/IconMap';
import type { SlotEntry, UIComponentProps, UIComponentSlots } from '../types/UI';
import { cn } from '../utils/classnames';
import { noop } from '../utils/noop';
import { renderSlot } from '../utils/slots';

export type SheetChildrenType = boolean | string | number | object | unknown;
export type SheetCloseFn = (result?: SheetChildrenType) => void | undefined;
export type SheetChildRenderer = (children?: SheetChildrenType) => React.ReactNode;

export type SheetRenderProps = UIComponentProps & {
  close?: SheetCloseFn;
};

export type SheetSlotEntry = SlotEntry<SheetRenderProps>;

export type SheetRenderCallback = (props: SheetRenderProps) => React.ReactNode;
export type SheetContentType = React.ReactNode | SheetRenderCallback;

export type SheetBaseProps = UIComponentProps &
  UIComponentSlots<SheetSlotEntry> & {
    footer?: SheetChildrenType;
    closeButton?: SheetChildrenType;
    onClose?: SheetCloseFn;
  };

export function SheetBase({
  className,
  closeButton = <CloseIcon />,
  onClose,
  children,
  footer,
  slots = {
    content: ({ className, children }: SheetRenderProps) => (
      <div className={cn('h-full flex-1 overflow-auto', className)}>{children}</div>
    ),
    closeButton: ({ close, children }: SheetRenderProps) => (
      <div
        className={cn('absolute right-2 top-2 cursor-pointer', className)}
        onClick={() => {
          close?.('close-btn');
        }}
      >
        {children}
      </div>
    ),
  },
  slotProps,
  classNames,
}: React.PropsWithChildren<SheetBaseProps>): React.ReactElement {
  const renderProps: SheetRenderProps = {
    close: onClose ?? noop,
  };

  /**
   * Prevent clicks behind the dialog content
   */
  const handleContainerClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  /**
   * Render children of this sheet
   * The children should be a function that accept sheetRenderProps
   */
  const renderChild: SheetChildRenderer = (children: SheetChildrenType) => {
    if (!children) return null;
    if (!React.isValidElement(children) && typeof children == 'function') {
      return children(renderProps);
    }
    return children;
  };

  return (
    <div
      className={cn('ui-sheet__box', ['bg-default text-base-content relative flex flex-col'], classNames?.box)}
      onClick={handleContainerClick}
    >
      {renderSlot(
        slots.closeButton,
        'ui-sheet__closeButton',
        { children: renderChild(closeButton), ...slotProps?.closeButton, ...renderProps },
        classNames?.closeButton
      )}

      {renderSlot(
        slots.content,
        'ui-sheet__content',
        { children: renderChild(children), ...slotProps?.content, ...renderProps },
        classNames?.content
      )}

      {renderSlot(
        slots.footer,
        'ui-sheet__footer',
        { children: renderChild(footer), ...slotProps?.footer, ...renderProps },
        classNames?.footer
      )}
    </div>
  );
}
