'use client';

import React from 'react';
import { getContrast } from '@wolfejs/core/utils/color';
import type { RecursiveKeyValuePair, UIComponentProps } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';

const defaultShades: Array<string> = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'];

export type ColorPaletteCompactProps = UIComponentProps & {
  name?: string;
  renderItem?(name: string, value: string): React.ReactNode;
  onColorClick?(color: string, shade: string): void;
  showLabels?: boolean;
  label?: string;
  shades?: string[];
  palette: RecursiveKeyValuePair<string, string>;
  append?: React.ReactNode;
  prepend?: React.ReactNode;
};

export function ColorPaletteCompact({
  showLabels = false,
  label = '-',
  append,
  prepend,
  palette,
  className,
  name,
  shades,
  renderItem,
  onColorClick,
}: ColorPaletteCompactProps) {
  function handleColorClick(color: string, _shade: string) {
    if (onColorClick) onColorClick(color, _shade);
  }

  if (!palette) return null;

  const value: string = palette['500'] as string;

  if (!value) return null;

  const defaultStyle = {
    background: value,
    color: value && typeof value === 'string' ? getContrast(value) : '#000',
  };

  const _shades: string[] = shades || defaultShades;

  return (
    <div className={cn('color-palette text-sm', className)}>
      <header style={defaultStyle} className="px-2 py-1">
        {prepend}
        <span>{label}</span>
        {append}
      </header>
      <main>
        {palette &&
          _shades.map((shade, i) => {
            const cvalue = palette[shade] as string;

            // if (cvalue.startsWith('var(')) {
            //   console.log('variable', cvalue);
            //   cvalue = computedStyle.getPropertyValue(cvalue);
            //   console.log('cvalue', cvalue);
            // }

            // if (!cvalue) {
            //   console.error(`shade ${shade} is empty in palette`, palette);
            //   throw new Error(`shade ${shade} is empty in palette`);
            // }

            const style = {
              background: cvalue,
              color: cvalue && typeof cvalue === 'string' ? getContrast(cvalue) : '#000',
            };

            return (
              <div className="px-2 py-[1px] " style={style} key={i} onClick={() => handleColorClick(cvalue, shade)}>
                {renderItem && name && renderItem(name, cvalue)}
                {!renderItem && showLabels && (
                  <span>
                    {shade} - {cvalue}&nbsp;
                  </span>
                )}
              </div>
            );
          })}
      </main>
    </div>
  );
}
