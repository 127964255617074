import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import useLatest from 'react-use/lib/useLatest';
import { cn } from '../utils/classnames';
import { NotificationBase, type NotificationBaseProps } from './NotificationBase';

export type NotificationProps = NotificationBaseProps & {
  open?: boolean;

  /**
   * Autoclose notification after some autoCloseDelay
   */
  autoClose?: boolean;

  /**
   * Number in milliseconds the notification should autoclose (if autoclose is set to true)
   */
  autoCloseDelay?: number;

  /**
   * Clear the autoclose timer when the notification is hovered.
   * The timer will restart when the user hovers out of the notification.
   */
  clearAutocloseOnHover?: boolean;

  onOpen?: () => void;
  onClose?: (reason?: unknown) => void;
  onClosed?: () => void;
  onOpened?: () => void;
};

function NotificationComp({
  children,
  open,
  autoClose = true,
  autoCloseDelay = 5000,
  clearAutocloseOnHover = true,
  onOpen,
  onOpened,
  onClosed,
  onClose,
  ...otherProps
}: NotificationProps) {
  const [opened, setOpened] = React.useState<boolean>(false);
  const [closing, setClosing] = React.useState<boolean>(false);
  const closingLatest = useLatest(closing);
  const openedLatest = useLatest(opened);

  const closeTimeout = React.useRef<NodeJS.Timeout | undefined>();

  const _close = React.useCallback(
    (reason?: unknown) => {
      const opened = openedLatest.current;

      if (!opened) return;
      setClosing(true);
      setOpened(false);
      onClose?.(reason);
    },
    [onClose, openedLatest]
  );

  const handleMouseEnter = () => {
    if (!clearAutocloseOnHover) return;
    if (autoClose) stopTimer();
  };

  const handleMouseLeave = () => {
    if (!clearAutocloseOnHover) return;
    if (autoClose) startTimer();
  };

  const startTimer = React.useCallback(() => {
    // stopTimer();
    if (autoCloseDelay) closeTimeout.current = setTimeout(() => _close('timeout'), autoCloseDelay);
  }, [_close, autoCloseDelay]);

  const stopTimer = React.useCallback(() => {
    if (closeTimeout.current) clearTimeout(closeTimeout.current);
  }, []);

  React.useEffect(() => {
    if (autoClose) startTimer();
    // return stopTimer;
  }, [autoClose, startTimer]);

  const handleOpened = React.useCallback(() => {
    if (open && !closing) {
      setOpened(true);
      onOpened?.();
      document.body.classList.add('has-overlay');
    }
  }, [closing, onOpened, open]);

  const handleClosed = React.useCallback(() => {
    onClosed?.();
    setClosing(false);
    document.body.classList.remove('has-overlay');
  }, [onClosed]);

  React.useEffect(() => {
    if (open) onOpen?.();
  }, [onOpen, open]);

  return (
    <AnimatePresence initial={false}>
      {open && (
        <motion.div
          initial={{ opacity: 0, marginTop: '20px' }}
          animate={{ opacity: 1, marginTop: '0px' }}
          exit={{ opacity: 0, marginTop: '20px', transition: { duration: 0.4 } }}
          className={cn('ui-notification-anim')}
          onAnimationComplete={() => {
            closingLatest.current ? handleClosed() : handleOpened();
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <NotificationBase {...otherProps}>{children}</NotificationBase>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export const Notification = NotificationComp as React.ComponentTypeExtended<NotificationProps>;
