'use client';

import React from 'react';
import type { ErrorFallbackProps } from './ErrorBoundaryFallback';
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';

export type ErrorBoundaryRenderProps = {
  error: string;
};

export type ErrorBoundaryProps = {
  children: React.ReactNode;
  // children: React.ReactNode | ((props: ErrorBoundaryRenderProps) => React.ReactNode);
  fallback?: React.FC<ErrorFallbackProps>;
  maxHeight?: number;
  onError?: (error: string) => void;
};

export type ErrorBoundaryInfo = {
  componentStack: string;
};

export type ErrorBoundaryState = {
  // hasError: boolean;
  error?: Error;
  errorInfo?: ErrorBoundaryInfo;
};

/**
 * Error Boundary component
 *
 * see: https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorBoundaryInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });

    console.error('error', error);
    console.info('errorInfo', errorInfo);

    this.props.onError?.(error.stack || error.message);
  }

  render() {
    // if (typeof this.props.children === 'function') {
    //   return this.props.children({ error: this.state.error?.message || 'Unknown Error' });
    // }

    if (this.state.error) {
      const Comp = this.props.fallback || ErrorBoundaryFallback;
      const props = { maxHeight: this.props.maxHeight, error: this.state.error, children: this.props.children };
      return <Comp {...props} />;
    }

    return this.props.children;
  }
}
