'use client';

import pjson from '@/../package.json';
import { Card } from '@wolfejs/ui/Card';
import { ColorPaletteEditor } from '@wolfejs/ui/ColorPaletteEditor';
import { Sheet } from '@wolfejs/ui/Sheet/Sheet';
import { Placement } from '@wolfejs/ui/types/UI';
import type { ThemeConfig } from '@wolfejs/ui/types/theme';
import React from 'react';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useLatest from 'react-use/lib/useLatest';
import { ColorPickers } from './ColorPickers';
import { DevToolBoxSection } from './DevToolBoxSection';
import { ResetThemeButton } from './ResetThemeButton';

const devToolsStorageKeyVisibility = 'ms-devtools';

const editableColors = ['background', 'foreground', 'heading', 'subheading', 'link'];

export function DevToolBox({ themeConfigs }: { themeConfigs: ThemeConfig[] }) {
  const [open, setOpen] = React.useState<boolean>(false);
  const openLatest = useLatest(open);

  const toggleVisibility = React.useCallback(() => {
    setOpen(!openLatest.current);
    localStorage.setItem(devToolsStorageKeyVisibility, JSON.stringify(!openLatest.current));
  }, [openLatest]);

  useEffectOnce(() => {
    // add a function to the top window to toggle the devtools
    // @ts-ignore
    window.top['devtools'] = () => toggleVisibility();

    // get initial visibility from storage
    if (localStorage.getItem(devToolsStorageKeyVisibility) === 'true') {
      setOpen(true);
    }
  });

  // React.useEffect(() => {
  //   const handleKeyDown = (e: KeyboardEvent) => {
  //     if (e.shiftKey && e.key === 'T') {
  //       toggleVisibility();
  //     }
  //   };
  //   window.addEventListener('keydown', handleKeyDown);
  //   return () => window.removeEventListener('keydown', handleKeyDown);
  // }, [toggleVisibility]);

  return (
    <Sheet
      className="theme-ninja"
      classNames={{ content: 'max-h-screen overflow-auto' }}
      open={open}
      onClose={() => {
        setOpen(false);
        localStorage.setItem(devToolsStorageKeyVisibility, JSON.stringify(false));
      }}
      placement={Placement.BottomRight}
      backdrop={false}
    >
      package.json version: {pjson.version}
      <Card classNames={{ content: 'p-2 flex flex-col gap-4' }}>
        <div className="mb-0 text-xl">Theming</div>

        <DevToolBoxSection title="Colors">
          <ColorPickers varNames={editableColors} className="gap-1" />
        </DevToolBoxSection>
        <DevToolBoxSection title="Color Palettes">
          <ColorPaletteEditor
            themeConfigs={themeConfigs}
            showPickerLabels
            hidePalettes
            className="mb-1 flex-col gap-1"
          />
        </DevToolBoxSection>

        <ResetThemeButton themeConfigs={themeConfigs} />
      </Card>
    </Sheet>
  );
}
