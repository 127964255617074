'use client';

import { cn } from '@wolfejs/ui/utils/classnames';
import React from 'react';
import { Button } from '../Button/Button';
import type { DialogCloseFn, DialogRenderProps } from '../Dialog/Dialog';
import { Modal } from '../Modal/Modal';
import type { DialogItem } from './api';
import { useDialogStore } from './store';

export type DialogType = 'alert' | 'confirm' | 'error' | 'warn' | 'success' | 'dialog';

export type DialogsProps = {};

export function Dialogs(): React.ReactElement {
  const store = useDialogStore();
  const items = store.items;

  function handleClose(item: DialogItem) {
    let closeResponse: boolean;
    if (item.onClose) {
      closeResponse = item.onClose(item.response);
      if (closeResponse === false) return false;
    }
  }

  function handleClosed(item: DialogItem) {
    if (item.id) {
      item.onPromiseResolve?.(item.response);
      useDialogStore.getState().remove(item.id);
    }
  }

  const wrapper = (
    <>
      {items?.map((modalItem, index) => {
        return (
          <DialogRenderer
            key={modalItem.id}
            item={modalItem}
            index={index}
            onClose={handleClose}
            onClosed={handleClosed}
          />
        );
      })}
    </>
  );

  return wrapper;
}

export type DialogRendererProps = {
  item: DialogItem;
  index: number;
  onClose?: (item: DialogItem) => void;
  onClosed: (item: DialogItem) => void;
};

function DialogRenderer({ item, onClose, onClosed, ...otherProps }: DialogRendererProps) {
  const { content, title, ...rest } = item;
  let dialogTitle = title;
  let isDismissable = true;
  if (title === undefined) {
    if (item.type === 'alert') {
      dialogTitle = 'Alert';
      isDismissable = false;
    }
    if (item.type === 'confirm') {
      dialogTitle = 'Confirm';
      isDismissable = false;
    }
    if (item.type === 'error') dialogTitle = 'Error';
    if (item.type === 'warn') dialogTitle = 'Warning';
    if (item.type === 'success') dialogTitle = 'Success';
  }

  const closeDialog = useDialogStore(state => state.close);
  const removeDialog = useDialogStore(state => state.remove);

  const close: DialogCloseFn = (reason?: unknown) => {
    item.response = reason;

    closeDialog(item.id);
    onClose?.(item);

    item.onPromiseResolve?.(reason);
  };

  const handleClosed: DialogCloseFn = (reason?: unknown) => {
    item.response = reason;

    removeDialog(item.id);
    onClosed?.(item);
  };

  function renderToolbar() {
    // eslint-disable-next-line react/display-name
    return ({ close }: DialogRenderProps) => {
      if (item.type === 'alert' || item.type === 'error' || item.type === 'warn' || item.type === 'success') {
        return (
          <>
            <Button onClick={() => close(true)}>OK</Button>
          </>
        );
      }
      if (item.type === 'confirm') {
        return (
          <>
            <Button onClick={() => close(false)} {...item.cancelButtonProps}>
              Cancel
            </Button>
            <Button onClick={() => close(true)} {...item.acceptButtonProps}>
              OK
            </Button>
          </>
        );
      }
    };
  }

  return (
    <Modal
      footer={renderToolbar()}
      closeOnEscape={isDismissable}
      closeOnBackdropClick={isDismissable}
      title={dialogTitle}
      {...otherProps}
      {...rest}
      className={cn(`ui-dialog ui-dialog-${item.type}`, item.className)}
      onClose={close}
      onClosed={handleClosed}
    >
      {content}
    </Modal>
  );
}
