import type { ColorPaletteConfig, ThemeConfig } from '../../types/theme';
import { colorShades } from '../UIStyling';
import flowbiteColorPalettes from './flowbiteColorPalettes';

const palettes: ColorPaletteConfig[] = [
  {
    id: 'gray',
    color: '#000',
    palette: flowbiteColorPalettes.gray,
  },
  {
    id: 'blue',
    color: '#000',
    palette: flowbiteColorPalettes.blue,
  },
  {
    id: 'purple',
    color: '#000',
    palette: flowbiteColorPalettes.purple,
  },
  {
    id: 'red',
    color: '#000',
    palette: flowbiteColorPalettes.red,
  },
  {
    id: 'orange',
    color: '#000',
    palette: flowbiteColorPalettes.orange,
  },
  {
    id: 'yellow',
    color: '#000',
    palette: flowbiteColorPalettes.yellow,
  },
  {
    id: 'green',
    color: '#000',
    palette: flowbiteColorPalettes.green,
  },
  {
    id: 'teal',
    color: '#000',
    palette: flowbiteColorPalettes.teal,
  },
  {
    id: 'indigo',
    color: '#000',
    palette: flowbiteColorPalettes.indigo,
  },
  {
    id: 'purple',
    color: '#000',
    palette: flowbiteColorPalettes.purple,
  },
  {
    id: 'pink',
    color: '#000',
    palette: flowbiteColorPalettes.pink,
  },
];

const theme: ThemeConfig = {
  id: 'flowbite',
  name: 'Flowbite theme',
  light: {
    paletteConfigs: palettes.concat([]),
    contextualMappings: [
      {
        id: 'default',
        paletteName: 'gray',
      },
      {
        id: 'primary',
        paletteName: 'blue',
      },
      {
        id: 'secondary',
        paletteName: 'purple',
      },
      {
        id: 'accent',
        paletteName: 'indigo',
      },
      {
        id: 'info',
        paletteName: 'blue',
      },
      {
        id: 'error',
        paletteName: 'red',
      },
      {
        id: 'warning',
        paletteName: 'yellow',
      },
      {
        id: 'success',
        paletteName: 'green',
      },
    ],
    contextualStates: (color: string) => {
      const shadeMappings: Record<string, string> = {};
      colorShades.forEach(shade => {
        shadeMappings[shade] = `var(--${color}-${shade})`;
      });

      return {
        __default__: {
          DEFAULT: `var(--${color}-700)`,
          hover: `var(--${color}-800)`,
          active: `var(--${color}-900)`,
          border: `var(--${color}-300)`,
          foreground: `var(--white)`,

          ...shadeMappings,
        },
        warning: {
          DEFAULT: `var(--${color}-400)`,
          hover: `var(--${color}-500)`,
          active: `var(--${color}-600)`,
        },
      };
    },
    contextualGradients: {
      __default__: {
        DEFAULT: 'from-${color}-500 via-${color}-600 to-${color}-700',
        hover: 'var(--${color}-400)',
        active: 'var(--${color}-500)',
        foreground: `var(--foreground)`,
      },
    },
    colors: {
      white: '#ffffff',
      black: '#000000',
      border: 'var(--gray-500)',
      shadow: 'rgba(59, 65, 66, .1)',

      lightest: '#F2F2F2',
      medium: '#8A8A8A',

      // backgrounds
      background: 'var(--gray-100)',
      section: 'var(--gray-400)',
      card: 'var(--gray-200)',
      'table-header': 'var(--gray-400)',

      // foregrounds
      foreground: 'var(--gray-900)',
      ['contextual-foreground']: 'var(--foreground)',
      muted: 'var(--gray-600)',
      code: '#5720b6',
      link: '#3b82f6',
      alert: 'var(--blue-800)',
      heading: 'var(--black)',
      [`subheading`]: 'var(--gray-500)',

      // scrollbar
      ['scrollbar-width']: '12px',
      ['scrollbar-bg']: 'var(--gray-200)',
      ['scrollbar-thumb']: 'var(--gray-400)',
      ['scrollbar-padding']: '2px',
      ['scrollbar-border-radius']: '30px',

      // Prose article
      '.prose': {
        [`tw-prose-body`]: 'var(--foreground)',
        [`tw-prose-quotes`]: 'var(--foreground)',
        [`tw-prose-headings`]: 'var(--heading)',
        [`tw-prose-links`]: 'var(--link)',
        [`tw-prose-bold`]: 'var(--foreground)',
      },
    },
  },
  dark: {
    paletteConfigs: palettes.concat([]),
    contextualMappings: [
      {
        id: 'default',
        paletteName: 'gray',
      },
      {
        id: 'primary',
        paletteName: 'blue',
      },
      {
        id: 'secondary',
        paletteName: 'purple',
      },
      {
        id: 'accent',
        paletteName: 'indigo',
      },
      {
        id: 'info',
        paletteName: 'blue',
      },
      {
        id: 'error',
        paletteName: 'red',
      },
      {
        id: 'warning',
        paletteName: 'yellow',
      },
      {
        id: 'success',
        paletteName: 'green',
      },
    ],
    contextualStates: (color: string) => {
      const shadeMappings: Record<string, string> = {};
      colorShades.forEach(shade => {
        shadeMappings[shade] = `var(--${color}-${shade})`;
      });

      return {
        __default__: {
          DEFAULT: `var(--${color}-600)`,
          hover: `var(--${color}-700)`,
          active: `var(--${color}-800)`,
          foreground: `var(--contextual-foreground)`,

          ...shadeMappings,
        },
        warning: {
          DEFAULT: `var(--${color}-400)`,
          hover: `var(--${color}-500)`,
          active: `var(--${color}-600)`,

          'alert-foreground': `var(--${color}-300)`,
        },
        default: {
          'alert-foreground': `var(--${color}-300)`,
        },
      };
    },
    contextualGradients: {
      __default__: {
        DEFAULT: 'from-${color}-500 via-${color}-600 to-${color}-700',
        hover: 'from-${color}-600 via-${color}-700 to-${color}-800',
        active: 'var(--${color}-500)',
        foreground: `var(--foreground)`,
      },
    },
    colors: {
      white: '#ffffff',
      black: '#000000',
      border: 'var(--gray-500)',
      shadow: 'rgba(59, 65, 66, 1)',

      lightest: '#F2F2F2',
      medium: '#8A8A8A',

      // backgrounds
      background: '#0B0F18',
      section: 'var(--gray-600)',
      card: 'var(--gray-700)',
      'table-header': 'var(--gray-700)',

      // foregrounds
      foreground: 'var(--gray-400)',
      ['contextual-foreground']: 'var(--white)',
      muted: 'var(--gray-400)',
      code: '#7E3AF2',
      link: '#3b82f6',
      heading: 'var(--white)',
      [`subheading`]: 'var(--gray-400)',

      // scrollbar
      ['scrollbar-width']: '12px',
      ['scrollbar-bg']: 'var(--gray-800)',
      ['scrollbar-thumb']: 'var(--gray-700)',
      ['scrollbar-padding']: '2px',
      ['scrollbar-border-radius']: '30px',

      // Prose article
      '.prose': {
        [`tw-prose-body`]: 'var(--foreground)',
        [`tw-prose-quotes`]: 'var(--foreground)',
        [`tw-prose-headings`]: 'var(--heading)',
        [`tw-prose-links`]: 'var(--link)',
        [`tw-prose-bold`]: 'var(--foreground)',
      },
    },
  },
};

export default theme;
