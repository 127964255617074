import type { PortalThemeSettings } from '@wolfejs/ms-ui/types/PortalThemeSettings';
import { produce } from 'immer';
import type { ThemeColors, ThemeColorsObject, ThemeContextualState, ThemeModeConfig } from '../types/theme';
import { type ColorPaletteConfig, type ThemeConfig } from '../types/theme';
import generateColorPalette from '../utils/material-palette';

type updateThemeCssVariableSetOptions = {
  theme?: string;
  themeMode?: string;
  selector?: string;
  // this is for passing a specific paletteConfig to use instead
  // of the defaults defined in the themeConfig
  paletteConfigs?: ColorPaletteConfig[];
};

/**
 * Update all css color variables and mappings for all themes in the themeConfig
 */
export function updateAllThemes(themeConfigs: ThemeConfig[], options?: updateThemeCssVariableSetOptions) {
  themeConfigs.map(config => {
    // updateThemeCssColorVariables(config, options);
    // updateThemeCssMappings(config, options);
    updateTheme(config, options);
  });
}

export function updatePortalVariables(
  themeConfig: ThemeConfig,
  portalThemeConfig: PortalThemeSettings,
  options?: updateThemeCssVariableSetOptions
) {
  const newThemeConfig = produce(themeConfig, draft => {
    if (!draft) return draft;

    if (portalThemeConfig.colorMapping) {
      draft.light.colors.foreground = portalThemeConfig.colorMapping.foreground;
      draft.light.colors.link = portalThemeConfig.colorMapping.link;
    }

    if (!portalThemeConfig.colorPalettes) return draft;

    const paletteNames = Object.keys(portalThemeConfig.colorPalettes);

    paletteNames.forEach(name => {
      const contextualMappings = draft.light.contextualMappings;
      const contextualMapping = contextualMappings.find(mapping => mapping.id === name);

      const paletteName = contextualMapping?.paletteName;

      if (!paletteName) return;

      const newColor = portalThemeConfig.colorPalettes[name];
      if (!newColor) return;

      const newPalette = generateColorPalette(newColor);
      const matchedItem = draft.light.paletteConfigs.find(_item => _item.id === paletteName);
      if (matchedItem) {
        matchedItem.palette = newPalette;
        matchedItem.color = newColor;
      }
    });

    return draft;
  });

  if (newThemeConfig) updateTheme(newThemeConfig, options);
}

export function updateTheme(themeConfig: ThemeConfig, options?: updateThemeCssVariableSetOptions) {
  const themeName = options?.theme || themeConfig.id;
  const themeMode = options?.themeMode;

  let styleId = 'theme-ui';
  if (themeName) styleId = `theme-${themeName}`;

  let cssEl = document.getElementById(styleId);
  if (!cssEl) {
    const styleEl = document.createElement('style');
    styleEl.id = styleId;
    document.head.appendChild(styleEl);
    cssEl = styleEl;
  }

  const output: string[] = [''];
  const selectors = [`.${themeMode}.theme-${themeName}`, `.${themeMode} .theme-${themeName}`];
  output.push(`${selectors.join(', ')} {`);

  const themeModeConfig = themeMode === 'light' ? themeConfig.light : themeConfig.dark;

  output.push(generateThemeCssColorVariables(themeModeConfig, options));
  output.push('\n');
  output.push(generateThemeContextualMappings(themeModeConfig, options));
  output.push('\n');
  output.push(generateThemeContextualGradients(themeModeConfig, options));
  output.push('\n');
  output.push(generateThemeCssColors(themeModeConfig.colors));
  output.push('\n}');

  output.push('\n\n');

  output.push(generateThemeCssColorsWithSelectors(themeModeConfig.colors, selectors));

  cssEl.textContent = output.join(' ');
}

export function generateThemeCssColorVariables(
  themeConfig: ThemeModeConfig,
  options?: updateThemeCssVariableSetOptions
) {
  // updateThemeCss(config, options);

  const paletteConfig = options?.paletteConfigs || themeConfig.paletteConfigs || [];

  const output: string[] = [];

  paletteConfig.forEach(item => {
    // generate css variables for base color palettes
    Object.keys(item.palette).forEach(shade => {
      output.push(`\n--${item.id}-${shade}: ${item.palette[shade] as string};`);
    });
  });

  return output.join(' ');
}

export function generateThemeCssColors(colors: ThemeColors | ThemeColorsObject) {
  const output: string[] = [''];

  Object.keys(colors).forEach(key => {
    const color = colors[key];

    if (typeof color !== 'object') {
      output.push(`--${key}: ${color};`);
    }
  });

  return output.join('\n');
}

export function generateThemeCssColorsWithSelectors(colors: ThemeColors | ThemeColorsObject, selectors: string[]) {
  const output: string[] = [];

  Object.keys(colors).forEach(key => {
    const color = colors[key];

    if (typeof color === 'object') {
      const selectorsWithSuffix = selectors.map(sel => `${sel} ${key}`);
      output.push(`${selectorsWithSuffix.join(', ')} {`);
      Object.keys(color).forEach(subkey => {
        const subColor = color[subkey];
        output.push(`--${subkey}: ${subColor};`);
      });
      output.push(`}`);
    }
  });

  return output.join('\n');
}

export function generateThemeContextualMappings(
  themeConfig: ThemeModeConfig,
  options?: updateThemeCssVariableSetOptions
) {
  const paletteConfig = options?.paletteConfigs || themeConfig.paletteConfigs || [];

  const output: string[] = [''];

  themeConfig.contextualMappings?.forEach(mapping => {
    // generate css variable mappings
    const _palette = paletteConfig.find(item => item.id === mapping.paletteName);

    if (!_palette) {
      console.warn(`color palette not found for mapping ${mapping.id}`);
      return;
    }

    const defaultState = themeConfig.contextualStates(_palette.id).__default__;

    const contextualState = themeConfig.contextualStates(_palette.id)[mapping.id] || {};

    output.push(generateThemeContextualStates({ ...defaultState, ...contextualState }, mapping.id, _palette.id));
  });

  return output.join('\n');
}

export function generateThemeContextualStates(
  contextualState: ThemeContextualState,
  mappingName: string,
  paletteName: string
) {
  const output: string[] = [];

  Object.keys(contextualState).forEach(key => {
    const value = contextualState[key];
    let varName = mappingName;
    if (key !== 'DEFAULT') varName += `-${key}`;
    output.push(`--${varName}: ${value.replaceAll('${color}', paletteName)};`);
  });

  return output.join('\n');
}

export function generateThemeContextualGradients(
  themeConfig: ThemeModeConfig,
  options?: updateThemeCssVariableSetOptions
) {
  const paletteConfig = options?.paletteConfigs || themeConfig.paletteConfigs || [];

  const output: string[] = [''];

  themeConfig.contextualMappings?.forEach(mapping => {
    // generate css variable mappings
    const _palette = paletteConfig.find(item => item.id === mapping.paletteName);

    if (!_palette) {
      console.warn(`color palette not found for mapping ${mapping.id}`);
      return;
    }

    const defaultState = themeConfig.contextualGradients?.__default__;

    const contextualGradients = themeConfig.contextualGradients?.[mapping.id] || {};

    output.push(generateThemeContextualGradient({ ...defaultState, ...contextualGradients }, mapping.id, _palette.id));
  });

  return output.join('\n');
}

export function generateThemeContextualGradient(
  contextualGradients: ThemeContextualState,
  mappingName: string,
  paletteName: string
) {
  const output: string[] = [];

  Object.keys(contextualGradients).forEach(key => {
    const value = contextualGradients[key];
    let varName = mappingName;
    if (key !== 'DEFAULT') varName += `-${key}`;
    output.push(`--grad-${varName}: ${value.replaceAll('${color}', paletteName)};`);
  });

  return output.join('\n');
}
