'use client';
import { themeConfigs } from '@/themes';
import { localstore } from '@/utils/storage';
import type { AppContextProps } from '@/wrappers/AppContext';
import { AppContext } from '@/wrappers/AppContext';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { isBrowser } from '@wolfejs/core/utils/environment';
import { MsClientProvider } from '@wolfejs/ms-api-react/src';
import { msQueryClient } from '@wolfejs/ms-api-react/src/client';
import { DevToolBox } from '@wolfejs/ms-ui/DevToolBox/DevToolBox';
import { Dialogs } from '@wolfejs/ui/Dialogs/Dialogs';
import { Notifications } from '@wolfejs/ui/Notifications/Notifications';
import { ReactQueryClientProvider } from '@wolfejs/ui/contexts/ReactQueryClientProvider';
import { ThemeProvider } from '@wolfejs/ui/contexts/ThemeContext';
import { ThemeMappingProvider } from '@wolfejs/ui/contexts/ThemeMappingProvider';
import { useUIStore } from '@wolfejs/ui/stores/UIStore';
import { CartProvider, MedusaProvider } from 'medusa-react';
import { Inter } from 'next/font/google';
import React, { useContext } from 'react';
// import { UseAppNavigation } from '../contexts/UseAppNavigation';
import { medusaClients } from '@/apiClients';
import '@/i18n/config';
import { routes } from '@/routes';
import { useAppStore } from '@/stores/appStore';
import { signout } from '@/utils/auth';
import { sessionStore } from '@/utils/storage';
import { PortalContext } from '@/wrappers/PortalContext';
import Medusa from '@medusajs/medusa-js';
import { UI } from '@wolfejs/ui/UI';
import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import { ThemeModes } from '@wolfejs/ui/types/theme';
import { cn } from '@wolfejs/ui/utils/classnames';
import Cookies from 'js-cookie';
import { useParams, useRouter } from 'next/navigation';
import { v4 as uuidv4 } from 'uuid';
import { useIdleTimer } from 'react-idle-timer'
import { inactivityTimeout } from "@/constants";

const inter = Inter({ subsets: ['latin'] });

// This ensures that Emotion's styles are inserted before Tailwind's styles so that Tailwind classes have precedence over Emotion (used by react-select)
const EmotionCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const cache = React.useMemo(
    () =>
      createCache({
        key: 'with-tailwind',
        // insertionPoint: document.querySelector('title')!,
      }),
    []
  );

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

// import { NavigationProvider } from '@wolfejs/ui/contexts/NavigationContext';

export type ComposeWrapperProps = {
  children?: React.ReactNode;
};

export type UILayoutProps = UIComponentProps & {
  children?: React.ReactNode;
  medusaCustomerToken?: string;
};

export type UILayoutImperativeProps = {
  toggleThemeMode: () => void;
};

export function UILayout({ children, className, medusaCustomerToken }: UILayoutProps) {
  // const { handleNavigateStart } = UseAppNavigation();

  // const theme = useUIStore(state => state.theme);

  // NOTE: the theme here is deliberately tracked separate from the UIStore and is deliberately set to an empty strin ginitially
  // on the client side we first need to render the UI without a theme and then initialize it with the correct one
  // this will make sure components will rerender themselves in the case where the SSR theme is different than the them used on the client.
  const [theme, setTheme] = React.useState<string>('');
  const [isLoggingOut, setIsLoggingOut] = React.useState<boolean>();
  // const [appContext, setAppContext] = React.useState<AppContextProps>();

  const router = useRouter();
  const { portalSlug: slug } = useParams();
  if (localstore) localstore.set('portal-slug', slug as string);

  const storeTheme = useUIStore(state => state.theme);
  const themeMode = useUIStore(state => state.themeMode);
  const setThemeMode = useUIStore(state => state.setThemeMode);
  const medusaAuthToken = useAppStore(state => state.medusaAuthToken);
  const setMedusaAuthToken = useAppStore(state => state.setMedusaAuthToken);

  let appContext2: AppContextProps | undefined = undefined;
  const portalContext = useContext(PortalContext);

  const onIdle = async () => {
    const activeUser = Cookies.get('user');
    if (activeUser) {
      if (await signout()) {
        router.push(`/${slug}/${routes.signin}`);
      }
    }
  };

  useIdleTimer({
    onIdle,
    timeout: inactivityTimeout,
  });

  /**
   * Update the theme on this context when the UIStore theme changes
   */
  React.useEffect(() => {
    if (storeTheme) setTheme(useUIStore.getState().theme);
  }, [storeTheme, theme, themeMode]);

  React.useEffect(() => {
    const browserId = Cookies.get('browserId');
    Cookies.set('browserId', browserId || uuidv4(), { expires: 400 });
    const sessionId = sessionStore?.get('sessionId');
    !sessionId && sessionStore?.set('sessionId', uuidv4());
  }, []);

  React.useEffect(() => {
    Cookies.set('slug', slug);
  }, [slug]);

  React.useEffect(() => {
    setThemeMode(ThemeModes.Light);
  }, [setThemeMode]);

  const performSignout = React.useCallback(async () => {
    const result = await signout();
    if (result == true) {
      router.push(`/${slug}/${routes.signin}`);
    } else {
      console.error(result);
    }
  }, [router, slug]);

  React.useEffect(() => {
    if (appContext2?.shouldLogout && !isLoggingOut) {
      UI.notifications.error('You account does not have access to Medusa store. You will be logged out.');
      setIsLoggingOut(true);
      performSignout();
    }
  }, [appContext2, isLoggingOut, performSignout]);

  return (
    <AppContext.Consumer>
      {context => {
        // setAppContext(context);
        appContext2 = context;

        if (context.accessToken && !medusaAuthToken) {
          setMedusaAuthToken(context.accessToken);
        }

        // Initialize the customerClient in the client context for use inside client components
        if (!medusaClients.customerClient) {
          medusaClients.customerClient = new Medusa({
            baseUrl: process.env.MEDUSA_BACKEND_URL || 'https://medusa-staging.wolfeops.com',
            maxRetries: 3,
            apiKey: context.accessToken,
          });
        }

        return (
          <ThemeProvider value={{ theme, setTheme, themeMode, setThemeMode }}>
            <body
              className={cn(inter.className, className, 'overflow-y-auto bg-background text-foreground')}
              style={{
                // initially render the body as hidden. it will be made visible when the app is ready (see ThemeModeToggleContainer).
                visibility: 'hidden',
              }}
            >
              <ThemeMappingProvider themeConfigs={themeConfigs} portalThemeSettings={portalContext?.settings} />
              <EmotionCacheProvider>
                <ReactQueryClientProvider>
                  <MsClientProvider
                    baseUrl={process.env.MS_API_URL || 'http://localhost:8060/api/v1'}
                    projectPrefix={process.env.PROJECT_PREFIX}
                    systemPrefix={process.env.SYSTEM_PREFIX}
                    options={{
                      portalAuthInterceptor: true,
                      refreshTokenInterceptor: false,
                    }}
                  >
                    <MedusaProvider
                      queryClientProviderProps={{ client: msQueryClient }}
                      baseUrl={process.env.MEDUSA_BACKEND_URL || 'http://localhost:8100'}
                      apiKey={medusaCustomerToken}
                      customHeaders={{
                        Authorization: `Bearer ${medusaCustomerToken}`,
                      }}
                    >
                      <CartProvider>
                        {children}

                        {/* <NProgressLoadingBarContainer /> */}
                        <ThemeProvider value={{ theme: 'ninja' }}>
                          <DevToolBox themeConfigs={themeConfigs} />
                        </ThemeProvider>
                        <div className="dialogs">{isBrowser && <Dialogs />}</div>
                        <div className="notifications">{isBrowser && <Notifications />}</div>
                      </CartProvider>
                    </MedusaProvider>
                  </MsClientProvider>
                </ReactQueryClientProvider>
              </EmotionCacheProvider>
            </body>
          </ThemeProvider>
        );
      }}
    </AppContext.Consumer>
  );
}
