import type { ThemeConfig } from '../../types/theme';
import type { ColorPaletteConfig } from '../../types/theme';
import generateMaterialPalette from '../../utils/material-palette';
import { colorShades } from '../UIStyling';

const palettes: ColorPaletteConfig[] = [
  {
    id: 'blue',
    color: '#1e40af  ',
    palette: generateMaterialPalette('#1e40af'),
  },
  {
    id: 'red',
    color: '#b91c1c',
    palette: generateMaterialPalette('#b91c1c'),
  },
  {
    id: 'orange',
    color: '#c2410c',
    palette: generateMaterialPalette('#c2410c'),
  },
  {
    id: 'green',
    color: '#16a34a',
    palette: generateMaterialPalette('#16a34a'),
  },
];

const contextualMappings = [
  {
    id: 'default',
    paletteName: 'gray',
  },
  {
    id: 'primary',
    paletteName: 'blue',
  },
  {
    id: 'secondary',
    paletteName: 'orange',
  },
  {
    id: 'accent',
    paletteName: 'green',
  },
  {
    id: 'info',
    paletteName: 'blue',
  },
  {
    id: 'error',
    paletteName: 'red',
  },
  {
    id: 'warning',
    paletteName: 'orange',
  },
  {
    id: 'success',
    paletteName: 'green',
  },
];

const theme: ThemeConfig = {
  id: 'ninja',
  name: 'Ninja theme',
  light: {
    paletteConfigs: palettes.concat([
      {
        id: 'gray',
        color: '#7e7e7e',
        palette: generateMaterialPalette('#7e7e7e'),
      },
    ]),
    contextualMappings,
    contextualStates: (color: string) => {
      const shadeMappings: Record<string, string> = {};
      colorShades.forEach(shade => {
        shadeMappings[shade] = `var(--${color}-${shade})`;
      });

      return {
        __default__: {
          DEFAULT: `var(--${color}-300)`,
          hover: `var(--${color}-400)`,
          active: `var(--${color}-500)`,
          foreground: `var(--gray-50)`,
          ...shadeMappings,
        },
      };
    },
    colors: {
      border: 'var(--gray-500)',

      // backgrounds
      background: 'var(--gray-100)',
      header: 'var(--gray-500)',
      section: 'var(--gray-400)',
      card: 'var(--gray-100)',
      'table-header': 'var(--gray-400)',

      // foregrounds
      foreground: 'var(--gray-900)',
      muted: 'var(--gray-300)',
      code: '#5720b6',
      link: '#3b82f6',
      heading: 'var(--black)',
      [`subheading`]: 'var(--gray-500)',

      // scrollbar
      ['scrollbar-width']: '12px',
      ['scrollbar-bg']: 'var(--gray-200)',
      ['scrollbar-thumb']: 'var(--gray-300)',
      ['scrollbar-padding']: '2px',
      ['scrollbar-border-radius']: '30px',

      // Prose article
      '.prose': {
        [`tw-prose-body`]: 'var(--foreground)',
        [`tw-prose-quotes`]: 'var(--foreground)',
        [`tw-prose-headings`]: 'var(--heading)',
        [`tw-prose-links`]: 'var(--link)',
        [`tw-prose-bold`]: 'var(--foreground)',
      },
    },
  },
  dark: {
    paletteConfigs: palettes.concat([
      {
        id: 'gray',
        color: '#4c4c4c',
        palette: generateMaterialPalette('#4c4c4c'),
      },
    ]),
    contextualMappings,
    contextualStates: (color: string) => {
      const shadeMappings: Record<string, string> = {};
      colorShades.forEach(shade => {
        shadeMappings[shade] = `var(--${color}-${shade})`;
      });

      return {
        __default__: {
          DEFAULT: `var(--${color}-800)`,
          hover: `var(--${color}-700)`,
          active: `var(--${color}-600)`,
          foreground: `var(--gray-50)`,
          ...shadeMappings,
        },
      };
    },
    colors: {
      border: 'var(--gray-800)',

      // backgrounds
      background: 'var(--gray-900)',
      header: 'var(--gray-700)',
      section: 'var(--gray-600)',
      card: 'var(--gray-500)',
      'table-header': 'var(--gray-600)',

      // foregrounds
      foreground: 'var(--gray-100)',
      muted: 'var(--gray-500)',
      code: '#7E3AF2',
      link: '#3b82f6',
      heading: 'var(--white)',
      [`subheading`]: 'var(--gray-400)',

      // scrollbar
      ['scrollbar-width']: '12px',
      ['scrollbar-bg']: 'var(--gray-700)',
      ['scrollbar-thumb']: 'var(--gray-600)',
      ['scrollbar-padding']: '2px',
      ['scrollbar-border-radius']: '30px',

      // Prose article
      '.prose': {
        [`tw-prose-body`]: 'var(--foreground)',
        [`tw-prose-quotes`]: 'var(--foreground)',
        [`tw-prose-headings`]: 'var(--heading)',
        [`tw-prose-links`]: 'var(--link)',
        [`tw-prose-bold`]: 'var(--foreground)',
      },
    },
  },
};

export default theme;
