import React from 'react';
import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';
import { Text } from '../Text/Text';

export type TitleProps = UIComponentProps & {
  as?: string;
};

/**
 * This is used to style titles outside of a article/prose context
 */
export const Title = ({ as = 'h1', children, className, ...otherProps }: TitleProps) => {
  // eslint-disable-next-line react/no-children-prop
  return React.createElement('span', {
    ...otherProps,
    children: <Text>{children}</Text>,
    className: cn(
      { 'block text-4xl mb-4 font-bold text-heading': as === 'h1' },
      { 'block text-2xl mb-4 font-bold text-heading': as === 'h2' },
      { 'block text-xl mb-2 font-bold text-heading': as === 'h3' },
      { 'block text-lg mb-2 font-bold text-heading': as === 'h4' },
      { 'block text-md mb-2 font-bold text-heading': as === 'h5' },
      { 'block text-md mb-2 font-bold text-heading': as === 'h6' },
      className
    ),
  });
};
