import type { PortalEntity } from '@wolfejs/ms-api-js/src/types/portals';
import type { UserEntity } from '@wolfejs/ms-api-js/src/types/users';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type AppStore = {
  user?: UserEntity;
  setUser: (user?: UserEntity) => void;
  resetPasswordToken?: string;
  setResetPasswordToken: (token: string) => void;
  portal?: PortalEntity;
  setPortal: (portal?: PortalEntity) => void;
  medusaAuthToken?: string;
  setMedusaAuthToken: (token?: string) => void;
  availableFunds?: number;
  setAvailableFunds: (availableFunds?: number) => void;
};

export const appStore: StateCreator<AppStore, [], [], AppStore> = set => ({
  user: undefined,
  setUser: (user?: UserEntity) => set(() => ({ user })),
  resetPasswordToken: undefined,
  setResetPasswordToken: (token: string) => set(() => ({ resetPasswordToken: token })),
  portal: undefined,
  setPortal: (portal?: PortalEntity) => set(() => ({ portal })),
  medusaAuthToken: undefined,
  setMedusaAuthToken: (token?: string) => set(() => ({ medusaAuthToken: token })),
  availableFunds: undefined,
  setAvailableFunds: (availableFunds?: number) => set(() => ({ availableFunds })),
});

export const useAppStore = create<AppStore>()(
  persist(appStore, {
    name: 'app',
  })
);
