'use client';

import { AnimatePresence, motion } from 'framer-motion';
import useMountedState from 'react-use/esm/useMountedState';
import { Notification } from '../Notification/Notification';
import type { UIComponentProps, UISize } from '../types/UI';
import { hAlignment, vAlignment } from '../types/UI';
import { cn } from '../utils/classnames';
import { useNotificationStore } from './store';

// const Alert = dynamic<AlertProps>(() => import('@inki/react-daisyui.alert').then(mod => mod.Alert), {
//   ssr: false,
// });

export type NotificationsProps = UIComponentProps & {
  /**
   * Size is passed to each Notification component
   */
  size?: UISize;

  /**
   * Vertical alignment of the notifications
   */
  vAlign?: vAlignment;

  /**
   * Horizontal alignment of the notifications
   */
  hAlign?: hAlignment;

  /**
   * Make this notification closable
   */
  autoClose?: boolean;

  /**
   * Number in milliseconds the notification should autoclose (if autoclose is set to true)
   */
  autoCloseTime?: number;

  portal?: HTMLElement;

  onClose?(notificationId: string): void;
  onClosed?: () => void;
  onOpened?: () => void;
};

export const Notifications = ({
  size,
  vAlign = vAlignment.Top,
  hAlign = hAlignment.Center,
  className,
  autoClose = true,
  autoCloseTime = 3000,
  // portal,
  onClose,
  ...otherProps
}: NotificationsProps) => {
  const isMounted = useMountedState();
  const removeNotification = useNotificationStore(state => state.remove);
  const notifications = useNotificationStore(state => state.items);

  function _close(id: string) {
    removeNotification(id);
  }

  function handleClose(id?: string) {
    if (!id) return;
    _close(id);
    if (onClose) onClose(id);
  }

  if (!isMounted()) return null;

  const wrapper = (
    <div
      className={cn(
        undefined,
        [{ vAlign }, { hAlign }, { [`size-${size}`]: size }],
        [
          'z-notifications fixed left-0 top-0 flex h-0 w-full',
          { 'flex-start': hAlign === hAlignment.Left },
          { 'justify-center': hAlign === hAlignment.Center },
          { 'flex-end': hAlign === hAlignment.Right },
        ]
      )}
      {...otherProps}
    >
      <ul className={cn('list', undefined, [className, 'mt-4 flex flex-col items-center space-y-2 text-center'])}>
        <AnimatePresence initial={false}>
          {notifications?.map(item => {
            const { children, ...otherItemProps } = item;
            const itemAutoClose = item.autoClose !== undefined ? item.autoClose : autoClose;
            const itemAutoCloseTime = item.autoCloseDelay !== undefined ? item.autoCloseDelay : autoCloseTime;

            return (
              <motion.li
                key={item.id}
                initial={{ opacity: 0, y: 50, scale: 0.3 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                className="inline-block"
              >
                <Notification
                  key={item.id}
                  id={item.id}
                  open
                  {...otherItemProps}
                  onClose={() => handleClose(item.id)}
                  autoClose={itemAutoClose}
                  autoCloseDelay={itemAutoCloseTime}
                >
                  {children}
                </Notification>
              </motion.li>
            );
          })}
        </AnimatePresence>
      </ul>
    </div>
  );

  // if (portal) renderPortal(wrapper, () => portal);
  return wrapper;
};
