/**
 * Get the contrast of a hex color.
 * Determines if the text should be light or dark based on the color
 *
 * @param hexcolor - The hex color code to analyze
 * @returns - 'black' if the contrast is high, 'white' if the contrast is low
 */
export function getContrast(hexcolor: string): string {
  // Validate input
  if (!hexcolor) throw new Error('no hexcolor provided');

  // Remove leading '#' if present
  if (hexcolor[0] === '#') {
    hexcolor = hexcolor.slice(1);
  }

  // Convert short hex color codes to full-length
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split('')
      .map(hex => {
        return hex + hex;
      })
      .join('');
  }

  // Convert hex to RGB
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Calculate YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast and return result
  return yiq >= 128 ? 'black' : 'white';
}
