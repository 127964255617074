import { CloseX } from '@wolfejs/icons/IconMap';
import { Button } from '@wolfejs/ui/Button/Button';
import React from 'react';
import type { SlotEntry, UIComponentSlots } from '../types/UI';
import { type UIComponentProps } from '../types/UI';
import { cn } from '../utils/classnames';
import { renderSlot } from '../utils/slots';
import { DialogTitle } from './DialogTitle';

export type DialogChildrenType = React.ReactNode | ((props: DialogRenderProps) => React.ReactNode);

export type DialogResponseType = unknown;
export type DialogCloseFn = (result?: DialogResponseType) => void | undefined;
export type DialogChildRenderer = (children?: DialogChildrenType) => React.ReactNode;

export type DialogRenderProps = UIComponentProps & {
  close: DialogCloseFn;
  scrollable?: boolean;
};

export type DialogSlotEntry = SlotEntry<DialogRenderProps>;

export type DialogSlots = {
  title?: DialogSlotEntry;
  content?: DialogSlotEntry;
  footer?: DialogSlotEntry;
  closeButton?: DialogSlotEntry;
};

export type DialogProps = Omit<UIComponentProps, 'children'> & {
  children?: DialogChildrenType;
} & UIComponentSlots<DialogSlotEntry> & {
    scrollable?: boolean;
    title?: DialogChildrenType;
    footer?: DialogChildrenType;
    closeButton?: DialogChildrenType;
    backdrop?: boolean;
    onClose?: DialogCloseFn;
  };

const noop = () => {};

export function Dialog({
  title,
  footer = false,
  scrollable = true,
  children,
  closeButton = (
    <Button size="xs" variant="outline">
      <CloseX className="h-2 w-2" />
    </Button>
  ),
  className,
  style,
  onClose,
  slots = {
    title: DialogTitle,
    footer: ({ className, children }: DialogRenderProps) => {
      if (!children) return null;
      return <div className={cn('border-t-default-200 flex gap-2  border-t p-4', className)}>{children}</div>;
    },
    content: ({ className, children, scrollable }: DialogRenderProps) => {
      return <div className={cn('flex-1 p-4', { 'overflow-auto': scrollable }, className)}>{children}</div>;
    },
    closeButton: ({ close }: DialogRenderProps) => {
      if (typeof closeButton === 'object') {
        const closeButtonWithClick = React.cloneElement(closeButton as React.ReactElement, {
          onClick: () => {
            close?.('close-btn');
          },
        });
        return closeButtonWithClick;
      }

      return null;
    },
  },
  slotProps,
  classNames,
}: DialogProps) {
  const renderProps = { close: onClose ?? noop };
  /**
   * Prevent clicks behind the dialog content
   */
  const handleContentClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
  };

  /**
   * Render children of this dialog
   * The children should be a function that accept DialogRenderProps
   */
  const renderChild: DialogChildRenderer = (children: DialogChildrenType) => {
    if (!children) return null;
    if (!React.isValidElement(children) && typeof children == 'function') {
      return children(renderProps);
    }
    return children;
  };

  return (
    <div
      className={cn('ui-dialog', [
        `dark:bg-default-700 text-foreground bg-white dark:text-white`,
        'relative flex flex-col rounded-lg shadow-xl',
        className,
        classNames?.dialog,
      ])}
      onClick={handleContentClick}
      style={style}
    >
      <div
        className={cn(
          'border-b-default-200 flex items-center justify-between gap-2 border-b p-4',
          classNames?.dialogTitle
        )}
      >
        {renderSlot(
          slots.title,
          'ui-dialog__title',
          { children: renderChild(title), ...slotProps?.title, ...renderProps },
          classNames?.title
        )}
        {closeButton && (
          <div className={cn('ui-dialog__actions')}>
            {renderSlot(
              slots.closeButton,
              'ui-dialog__closeButton',
              { children: renderChild(closeButton), ...slotProps?.closeButton, ...renderProps },
              classNames?.closeButton
            )}
          </div>
        )}
      </div>

      {renderSlot(
        slots.content,
        'ui-dialog__content',
        { children: renderChild(children), ...slotProps?.content, ...renderProps, scrollable },
        classNames?.content
      )}

      {renderSlot(
        slots.footer,
        'ui-dialog__footer',
        { children: renderChild(footer), ...slotProps?.footer, ...renderProps },
        classNames?.footer
      )}
    </div>
  );
}
