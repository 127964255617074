import englishTranslations from '@wolfejs/ms-ui/i18n/en';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import en, { zodValidations as zodValidationsEn } from './en';
import fr, { zodValidations as zodValidationsFr } from './fr';

i18next.use(initReactI18next).init({
  lng: 'en-US', // if you're using a language detector, do not define the lng option
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    'en-US': {
      translation: en,
      zod: {
        ...zodValidationsEn,
        invalid_string: {
          email: englishTranslations.validation.invalidEmail,
        },
      },
    },
    'fr-FR': {
      translation: fr,
      zod: zodValidationsFr,
    },
  },
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz" set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
});

z.setErrorMap(zodI18nMap);
