import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import type { ThemeConfig } from '@wolfejs/ui/types/theme';

export type ThemeStore = {
  themeConfigs?: ThemeConfig[];
  setThemeConfigs: (themeConfigs: ThemeConfig[]) => void;
};

export const themeStore: StateCreator<ThemeStore, [], [], ThemeStore> = set => ({
  themeConfigs: undefined,
  setThemeConfigs: (themeConfigs?: ThemeConfig[]) => set(() => ({ themeConfigs })),
});

export const useThemeStore = create<ThemeStore>()(
  // persist(themeStore, {
  //   name: 'themes',
  // })
  themeStore
);
