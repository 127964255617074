'use client';

import React from 'react';
import { Button } from '@wolfejs/ui/Button';
import { useThemeStore } from '@wolfejs/ui/stores/ThemeStore';
import { useUIStore } from '@wolfejs/ui/stores/UIStore';
import { updateTheme } from '@wolfejs/ui/styles/themeGenerator';
import type { ThemeConfig } from '@wolfejs/ui/types/theme';

export function ResetThemeButton({ themeConfigs }: { themeConfigs: ThemeConfig[] }) {
  const theme = useUIStore(state => state.theme);
  const themeMode = useUIStore(state => state.themeMode);

  const _themeConfigs = useThemeStore(state => state.themeConfigs);
  const setThemeConfigs = useThemeStore(state => state.setThemeConfigs);

  const config = _themeConfigs?.find(conf => conf.id === theme);

  const handleResetAll = React.useCallback(() => {
    if (!config) return;

    // reset to default color palettes
    setThemeConfigs(themeConfigs);
    updateTheme(config, { theme, themeMode });
  }, [config, setThemeConfigs, theme, themeConfigs, themeMode]);

  return <Button onClick={handleResetAll}>Reset Theme</Button>;
}
