'use client';

import React from 'react';
import { InputColor, type InputColorProps } from './InputColor';

export type InputColorVarProps = InputColorProps & {
  variable?: string;
  selector?: string;
};

export const InputColorVar = ({
  selector,
  variable,
  value,

  onValueChange,
  ...otherProps
}: InputColorVarProps) => {
  const rootEl = React.useRef<HTMLElement>();

  const [_value, setValue] = React.useState<string | undefined>(value);

  const isControlled = !!value;
  const val = isControlled ? value : _value;

  const handleValueChange = React.useCallback(
    (color?: string) => {
      onValueChange?.(color || '');

      if (!isControlled) setValue(color);
    },
    [onValueChange, isControlled]
  );

  React.useEffect(() => {
    if (!variable || !selector) return;

    rootEl.current = document.querySelector(selector) as HTMLElement;
    if (!rootEl.current) return;
    const computedStyle = getComputedStyle(rootEl.current);

    setValue(computedStyle.getPropertyValue(variable));
  }, [selector, variable]);

  return <InputColor {...otherProps} value={val} onValueChange={handleValueChange} />;
};
