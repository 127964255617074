'use client';

import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import useLatest from 'react-use/lib/useLatest';
import type { UIComponentSlots, UIThemableComponent, hAlignment, vAlignment } from '../types/UI';
import { Placement } from '../types/UI';
import { cn } from '../utils/classnames';
import { renderPortal } from '../utils/renderPortal';
import type { SheetChildrenType, SheetCloseFn, SheetSlotEntry } from './SheetBase';
import { SheetBase } from './SheetBase';

export type SheetProps = UIThemableComponent &
  UIComponentSlots<SheetSlotEntry> & {
    open?: boolean;
    closeOnBackdropClick?: boolean;
    closeOnEscape?: boolean;
    closeButton?: SheetChildrenType;
    openAnimationDuration?: number;
    closeAnimationDuration?: number;
    portal?: HTMLElement;
    placement?: Placement;
    hAlign?: hAlignment;
    vAlign?: vAlignment;
    contained?: boolean;
    backdrop?: boolean;
    useDocumentClick?: boolean;
    padded?: boolean;
    shadow?: boolean;
    transitionDuration?: number;
    openAnimationLength?: number;
    closeAnimationLength?: number;
    autoScrollContentTo?: 'top' | 'bottom' | { id: string };
    contentRef?: React.MutableRefObject<HTMLDivElement>;
    onClose?: SheetCloseFn;
    onClosed?(): void;
    onOpen?(): void;
  };

export function Sheet({
  className,
  classNames,
  open = false,
  useDocumentClick,
  closeOnBackdropClick = true,
  closeOnEscape = true,
  closeButton,
  backdrop = true,
  placement = Placement.Left,
  onClose,
  onClosed,
  onOpen,
  portal,
  contained = false,
  transitionDuration = 0.3,
  ...otherProps
}: React.PropsWithChildren<SheetProps>): React.ReactElement {
  const [opened, setOpened] = React.useState<boolean>(open);
  const openedLatest = useLatest(opened);

  const handleDocumentClose = React.useCallback(() => {
    if (!openedLatest.current) return;
    if (useDocumentClick) document.removeEventListener('click', handleDocumentClose);
    setOpened(false);
    onClose?.('document-click');
  }, [onClose, openedLatest, useDocumentClick]);

  const close = React.useCallback(
    (reason?: unknown) => {
      if (!opened) return;
      setOpened(false);
      onClose?.(reason);

      if (useDocumentClick) document.removeEventListener('click', handleDocumentClose);
    },
    [handleDocumentClose, onClose, opened, useDocumentClick]
  );

  const _open = React.useCallback(() => {
    if (opened) return;
    document.body.classList.add('has-overlay');
    setOpened(true);
    onOpen?.();

    if (!useDocumentClick) return;
    setTimeout(() => {
      document.addEventListener('click', handleDocumentClose);
    }, 100);
  }, [opened, onOpen, useDocumentClick, handleDocumentClose]);

  const handleKeyDown = React.useCallback(
    (ev: KeyboardEvent) => {
      if (ev.code === 'Escape') {
        if (closeOnEscape) close('escape');
      }
    },
    [close, closeOnEscape]
  );

  function handleBackdropClick(ev: React.MouseEvent<HTMLDivElement>) {
    ev.stopPropagation();
    if (closeOnBackdropClick) close();
  }

  function handleClosed() {
    document.body.classList.remove('has-overlay');
    onClosed?.();
  }

  React.useEffect(() => {
    if (open) {
      _open();
    } else {
      close();
    }
  }, [open, _open, close]);

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  let startX = '0';
  let startY = '0';
  if (placement === Placement.Left) startX = '-100%';
  if (placement === Placement.Right) startX = '100%';
  if (placement === Placement.Top) startY = '-100%';
  if (placement === Placement.Bottom) startY = '100%';

  if (placement === Placement.Bottom) startX = '0';
  if (placement === Placement.Top) startX = '0';

  const variants = {
    start: { translateX: startX, translateY: startY },
    end: { translateX: 0, translateY: 0 },
  };

  const Sheet = (
    <div className={cn('ui-sheet z-sheet', [{ 'ui-sheet--open': open }, { fixed: contained !== true }], className)}>
      {backdrop && (
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={cn(
                'ui-sheet__backdrop',
                undefined,
                'absolute inset-0 z-10 overflow-hidden bg-black bg-opacity-20'
              )}
              onClickCapture={handleBackdropClick}
            />
          )}
        </AnimatePresence>
      )}

      <AnimatePresence onExitComplete={handleClosed}>
        {open && (
          <motion.div
            initial={'start'}
            variants={variants}
            animate={'end'}
            exit={'start'}
            transition={{
              duration: transitionDuration,
              damping: 5,
            }}
            className={cn('ui-sheet__content', [
              'bg-default text-base-content z-20 flex flex-col shadow-xl',
              { absolute: contained },
              { fixed: contained !== true },
              {
                'left-0': placement === Placement.Left || placement === Placement.Bottom || placement === Placement.Top,
              },
              {
                'right-0':
                  placement === Placement.Right ||
                  placement === Placement.BottomRight ||
                  placement === Placement.TopRight,
              },
              { 'top-0': placement === Placement.Top || placement === Placement.Left || placement === Placement.Right },
              {
                'bottom-0':
                  placement === Placement.Bottom ||
                  placement === Placement.BottomRight ||
                  placement === Placement.BottomLeft,
              },

              { 'h-full w-[240px]': placement === Placement.Left || placement === Placement.Right },
              { 'h-[200px] w-full': placement === Placement.Top || placement === Placement.Bottom },
              {
                'min-h-[100px] min-w-[240px]':
                  placement === Placement.BottomRight ||
                  placement === Placement.BottomLeft ||
                  placement === Placement.TopRight ||
                  placement === Placement.TopLeft,
              },
              classNames?.content,
            ])}
          >
            <SheetBase
              classNames={classNames}
              closeButton={closeButton}
              {...otherProps}
              onClose={() => close('closeButton')}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  if (portal) renderPortal(Sheet, () => portal);

  return Sheet;
}
