import type { PortalThemeSettings } from '@wolfejs/ms-ui/types/PortalThemeSettings';

export const defaultThemeSettings: PortalThemeSettings = {
  colorMapping: {},
  colorPalettes: {},
};

export enum CookiesKeys {
  medusaCartId = '_medusa_cart_id',
  medusaSalesChannelId = '_medusa_sales_channel_id',
  medusaMerchantToken = 'medusaMerchantToken',
  brandId = 'brandId',
  user = 'user',
}

export const CookieExpiresOneWeek = 60 * 60 * 24 * 7 * 1000;
export const CookieExpiresMax = 60 * 60 * 24 * 400 * 1000;
export const shipping_option_id = 'so_01HW7PATWGDN6GV0XXB4RTD823';
export const inactivityTimeout = 1000 * 60 * 60;

export const mixedCartErrorMessage = "Products added to cart must either be all physical or all digital."
