'use client';

import React from 'react';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import type { HorVerLayout, UIComponentProps, UIThemableComponent } from '../types/UI';
import { LayoutTypes, UIColorContext, UIShapes, UISizes, UIVariants } from '../types/UI';
import { cn } from '../utils/classnames';
import { renderSlot } from '../utils/slots';

export type CardProps = UIThemableComponent<UIComponentProps<HTMLDivElement>, HTMLDivElement> & {
  ref?: React.ForwardedRef<HTMLDivElement>;
  noPad?: boolean;

  layout?: HorVerLayout;
  image?: string;
  alt?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  actions?: React.ReactNode;
};

const CardComp = (
  {
    cname = 'ui-card',
    children,
    className,

    // ----- Themable props defaults
    color = UIColorContext.default,
    variant = UIVariants.solid,
    shape = UIShapes.rounded,
    size = UISizes.md,
    colorShadows,
    gradient,
    elevation,

    // ----- Options
    noPad,
    title,
    description,
    actions,
    image,
    alt,
    layout = LayoutTypes.vertical,

    // ----- Slots
    slots = {
      title: ({ className, children }: UIComponentProps) => (
        <div className={cn('mb-1 text-xl font-bold', className)}>{children}</div>
      ),
      description: ({ className, children }: UIComponentProps) => (
        <div className={cn('text-muted', className)}>{children}</div>
      ),
      actions: ({ className, children }: UIComponentProps) => (
        <div className={cn('absolute right-5 top-5', className)}>{children}</div>
      ),
    },
    slotProps,
    classNames,

    ...otherProps
  }: CardProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) => {
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation, colorShadows };

  const classes = React.useMemo(() => {
    const themableProps = { variant, color, shape, size, theme, gradient, elevation, colorShadows };

    return cn(
      cname,
      'relative',
      {
        // [`bg-${color} text-${color}-foreground`]: variant === UIVariants.solid && !gradient,
        [`bg-${color}-200 dark:bg-${color}-700 dark:text-white text-${color}-900`]:
          variant === UIVariants.solid && !gradient,
        [`bg-gradient-to-tr from-${color}-400 via-${color}-300 to-${color}-200 dark:from-${color}-900 dark:via-${color}-800 dark:to-${color}-700`]:
          variant === UIVariants.solid && gradient,
        [`border border-transparent`]: variant === UIVariants.solid || variant === UIVariants.ghost,
        [`border border-${color}-200 dark:border-${color}-700`]:
          variant === UIVariants.outline || variant === UIVariants.solid,
        // Outline components only have a border and apply text color
        [`text-${color}`]: variant === UIVariants.outline,

        [`flex`]: layout === LayoutTypes.horizontal,
      },

      UIStyling.shapes(themableProps),
      UIStyling.textSizes(themableProps),
      UIStyling.elevations(themableProps),
      colorShadows && UIStyling.colorShadows(themableProps),

      className
    );
  }, [className, cname, color, colorShadows, elevation, gradient, layout, shape, size, theme, variant]);

  return (
    <div ref={forwardedRef} {...otherProps} className={classes}>
      {image && (
        <img
          className={cn(
            'not-prose w-full max-w-full',
            { [`rounded-t-lg`]: shape === UIShapes.rounded && layout === LayoutTypes.vertical },
            { [`rounded-l-lg`]: shape === UIShapes.rounded && layout === LayoutTypes.horizontal },
            classNames?.image
          )}
          src={image}
          alt={alt || 'Card image'}
        />
      )}
      <div className={cn('flex-1', { ...(noPad ? {} : UIStyling.boxPaddings(themableProps)) }, classNames?.wrapper)}>
        {(title || description) && (
          <header className={cn('mb-4', classNames?.header)}>
            {title &&
              renderSlot(slots.title, 'ui-card__title', { children: title, ...slotProps?.title }, classNames?.title)}
            {description &&
              renderSlot(
                slots.description,
                'ui-card__description',
                { children: description, ...slotProps?.description },
                classNames?.description
              )}
            {actions &&
              renderSlot(
                slots.actions,
                'ui-card__actions',
                { children: actions, ...slotProps?.actions },
                classNames?.actions
              )}
          </header>
        )}
        <main className={cn('ui-card__content flex-1', classNames?.content)}>{children}</main>
      </div>
    </div>
  );
};

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  CardComp
) as React.ForwardRefExoticComponentExtended<CardProps>;

export const CardVariants = [UIVariants.solid, UIVariants.outline, UIVariants.unstyled];
