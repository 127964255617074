'use client';

import type { PortalThemeContext } from '@wolfejs/ms-ui/types/PortalThemeSettings';
import React, { createContext } from 'react';

export const PortalContext = createContext<PortalThemeContext | undefined>({
  id: '',
  settings: {
    // Setting default Wolfe theme in case if portal theme fetch will fail
    colorPalettes: {
      primary: '#fe8325',
    },
    colorMapping: {
      link: '#3F83F8',
      foreground: '#111827',
    },
  },
});
export type PortalContextProviderProps = {
  children: React.ReactNode;
  settings?: PortalThemeContext;
};
export const PortalContextProvider = ({ settings, children }: PortalContextProviderProps) => {
  return <PortalContext.Provider value={settings}>{children}</PortalContext.Provider>;
};
